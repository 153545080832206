import {
  Assessment,
  EvidenceColumn,
  ReactSelectOption,
  Student,
  StudentEntry,
} from "../onboarding/types";

export type GroupsState = {
  skillFocusInfo: Array<SkillFocusArea>;
  identifiersInfo: { [identifier: string]: Identifier };
  grouping?: GroupingStructure;
  selectedAssessment?: Assessment;
  selectedStudentEntry?: StudentEntry;
  evidenceColumnGroupId?: number,
  evidenceColumn?: EvidenceColumn,
  studentId?: number,
  student?: Student,
  identifiersOrderList: string[];
  overviewModelType: string; 
  modalsState: {
    mergeDataModal: boolean;
    skillFocusesModal: boolean;
    behaviorScreenerModal: boolean;
    confirmDiagnosisModal: boolean;
  };
};

export enum GroupsActionTypes {
  GET_SKILL_FOCUSES_START = "@@onboarding/GET_SKILL_FOCUSES_START",
  GET_SKILL_FOCUSES_SUCCESS = "@@onboarding/GET_SKILL_FOCUSES_SUCCESS",
  GET_SKILL_FOCUSES_FAILURE = "@@onboarding/GET_SKILL_FOCUSES_FAILURE",

  OPEN_MERGE_DATA_MODAL = "@@onboarding/OPEN_MERGE_DATA_MODAL",
  HIDE_MERGE_DATA_MODAL = "@@onboarding/HIDE_MERGE_DATA_MODAL",

  OPEN_CONFIRM_DIAGNOSIS_MODAL = "@@onboarding/OPEN_CONFIRM_DIAGNOSIS_MODAL",
  HIDE_CONFIRM_DIAGNOSIS_MODAL = "@@onboarding/HIDE_CONFIRM_DIAGNOSIS_MODAL",
  ACCEPT_CONFIRM_DIAGNOSIS_MODAL = "@@onboarding/ACCEPT_CONFIRM_DIAGNOSIS_MODAL",

  OPEN_SKILL_FOCUSES_MODAL = "@@onboarding/OPEN_SKILL_FOCUSES_MODAL",
  HIDE_SKILL_FOCUSES_MODAL = "@@onboarding/HIDE_SKILL_FOCUSES_MODAL",

  CLEAR_SELECTED_ASSESSMENT = "@@onboarding/CLEAR_SELECTED_ASSESSMENT",

  OPEN_OVERVIEW_MODAL = "@@onboarding/OPEN_OVERVIEW_MODAL", //Rakesh Bhargava 6 april 2022
  OPEN_BEHAVIOR_SCREENER_MODAL = "@@onboarding/OPEN_BEHAVIOR_SCREENER_MODAL",
  HIDE_BEHAVIOR_SCREENER_MODAL = "@@onboarding/HIDE_BEHAVIOR_SCREENER_MODAL",
}

export type Identifier = {
  identifier_info: [string, string, GroupKey, GroupKey];
  area_identifier: string;
  skill_focus_identifier?: string;
  specific_identifier?: string;
  name: string;
  type: "area" | "skill_focus" | "specific" | "behavior_rec" | "read_rec";
};

export type SkillFocusArea = {
  display_name: string;
  identifier: string;
  skill_focuses?: Array<SkillFocus>;
};

export type SkillFocus = {
  display_name: string;
  identifier: string;
  specifics?: Array<Specific>;
  specifics_display_style: string;
};

export type Specific = {
  identifier: string;
  display_name: string;
};

export enum ReadingSkillType {
  PhonemicAwareness = "phonemic_awareness",
  Phonics = "phonics",
  Fluency = "fluency",
  Comprehension = "comprehension",
  Vocabulary = "vocab",
}

export const ReadingSkillTypeDisplayedNames: {
  [key in ReadingSkillType]: string;
} = {
  [ReadingSkillType.PhonemicAwareness]: "Phonemic Awareness",
  [ReadingSkillType.Phonics]: "Phonics",
  [ReadingSkillType.Fluency]: "Fluency",
  [ReadingSkillType.Comprehension]: "Comprehension",
  [ReadingSkillType.Vocabulary]: "Vocabulary",
};

export enum TimePeriod {
  Beginning = "boy",
  Middle = "moy",
  End = "eoy",
}

export const TimePeriodOptions: ReactSelectOption<TimePeriod>[] = [
  { value: TimePeriod.Beginning, label: "Beginning of Year (Fall)" },
  { value: TimePeriod.Middle, label: "Middle of Year (Winter)" },
  { value: TimePeriod.End, label: "End of Year (Spring)" },
];

export const TimePeriodWithoutLabel: ReactSelectOption<TimePeriod>[] = [
  { value: TimePeriod.Beginning, label: "Beginning of Year" },
  { value: TimePeriod.Middle, label: "Middle of Year" },
  { value: TimePeriod.End, label: "End of Year" },
];

export const TimePeriodShortOptions: ReactSelectOption<TimePeriod>[] = [
  { value: TimePeriod.Beginning, label: "Beginning" },
  { value: TimePeriod.Middle, label: "Middle" },
  { value: TimePeriod.End, label: "End" },
];

export const getTimePeriodLabel = (timePeriod: TimePeriod) => {
  for (const tp of TimePeriodOptions) {
    if (tp.value === timePeriod) {
      return tp.label;
    }
  }

  return "";
};

export const getTimePeriodWithoutLabel = (timePeriod: TimePeriod) => {
  for (const tp of TimePeriodWithoutLabel) {
    if (tp.value === timePeriod) {
      return tp.label;
    }
  }

  return "";
};

export type GroupKey =
  | "A"
  | "B"
  | "C"
  | "D"
  | "A1"
  | "A2"
  | "A3"
  | "A4"
  | "B1"
  | "B2"
  | "B3";

export type MTSSTier = 1 | 2 | 3;

export type GradeTimePeriodBucket = {
  group_key: GroupKey;
  sub_group_key?: GroupKey;
  tier: MTSSTier;
};

export type GradeTimePeriod = Array<GradeTimePeriodBucket>;
export type GradeGroupings = Record<TimePeriod, GradeTimePeriod>;

export type GroupingStructure = Record<Grade, GradeGroupings>;

export type Grade =
  | "K"
  | "G1"
  | "G2"
  | "G3"
  | "G4"
  | "G5"
  | "G6"
  | "G7"
  | "G8"
  | "G9"
  | "G10"
  | "G11"
  | "G12";

export const TierDisplayValues = {
  1: "Tier 1",
  2: "Tier 2",
  3: "Tier 3",
};

export const TierValues = [
  { value: 1, label: TierDisplayValues[1] },
  { value: 2, label: TierDisplayValues[2] },
  { value: 3, label: TierDisplayValues[3] },
];

export enum FileType {
  Big = "big",
  Small = "small",
  Third = "third"
}

export const FileTypeOptions: ReactSelectOption<FileType>[] = [
  { value: FileType.Big, label: "Big" },
  { value: FileType.Small, label: "Small" },
  { value: FileType.Third, label: "Third" },
];
