import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import Select, { ValueType } from "react-select";
import {
  RenewedTeacherInfo,
  School,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import RoleSelector from "./RoleSelector";
import { ApplicationState } from "../../../../../store";
import { connect, useDispatch, useSelector } from "react-redux";
import { districtRoles } from "../../../../../store/auth/constants";
import {
  changeUserStatus,
  getSchools,
} from "../../../../../store/onboarding/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUndo } from "@fortawesome/free-solid-svg-icons";
import { showConfirmDialog } from "../../../../../store/confirm-dialog/actions";
import { resetPassword } from "../../../../../store/auth/actions";
import { useLoading } from "../../../../../utils/hooks/useLoading";
import { toastr } from "react-redux-toastr";

type StateProps = {
  hasDistrictPermission?: boolean;
  schools: Array<School>;
  selectedTeacher?: TeacherInfo;
};
type DispatchProps = {};
type OwnProps = {
  isHighLevelRole: boolean;
  handleSubmit: (renewedTeacherInfo: RenewedTeacherInfo) => any;
};

type Props = OwnProps & StateProps & DispatchProps;

const TeacherInfoForm: FunctionComponent<Props> = (props) => {
  const {
    isHighLevelRole,
    selectedTeacher,
    schools,
    hasDistrictPermission,
    handleSubmit,
  } = props;

  const [renewedTeacherInfo, setRenewedTeacherInfo] = useState<
    RenewedTeacherInfo
  >({
    first_name: "",
    last_name: "",
    email: "",
    schools: [],
    role: undefined,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSchools());
  }, []);

  useEffect(() => {
    if (selectedTeacher) {
      const schools =
        selectedTeacher?.user.profile.current_assignment?.schools.map(
          (school) => school.id
        ) ?? [];
        
      setRenewedTeacherInfo({
        first_name: selectedTeacher.user.first_name,
        last_name: selectedTeacher.user.last_name,
        email: !selectedTeacher.user?.email?.includes('@njtss_user_imported') ? selectedTeacher.user.email : '',
        schools: schools,
        //role: selectedTeacher.user.profile.current_assignment?.role,
      });
    }
  }, [selectedTeacher]);

  const handleTeacherNameChange = (event: React.FormEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    setRenewedTeacherInfo((ps) => ({ ...ps, [name]: value }));
  };

  const handleEmailChange = (event: React.FormEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    setRenewedTeacherInfo((ps) => ({ ...ps, [name]: value }));
  };

  const handleSchoolsChange = (newValue?: ValueType<School, true>) => {
    const newSchools = newValue
      ? (newValue as Array<School>).map((val) => val.id)
      : [];
    setRenewedTeacherInfo((ps) => ({ ...ps, schools: newSchools }));
  };

  const handleRoleChange = (newRole?: ValueType<any, false>) => {
    setRenewedTeacherInfo((ps) => ({ ...ps, role: newRole }));
  };

  const loadingResetPassword = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.auth.isLoading.resetPassword);
  const errorResetPassword = useSelector<ApplicationState, string | undefined>(
    (s) => s.auth.errors.resetPassword
  );
  const onResetPasswordSuccess = useCallback(() => {
    toastr.success("Success", "An email has been sent.");
  }, []);
  useLoading(loadingResetPassword, errorResetPassword, onResetPasswordSuccess);
  const handlePasswordResetConfirm = () => {
    const onConfirm = () => {
      if (selectedTeacher?.user.email) {
        dispatch(resetPassword(selectedTeacher.user.email));
      }
    };
    dispatch(
      showConfirmDialog({
        onConfirm,
        centered: true,
        title: "Reset Password",
        text: (
          <>
            Are you sure you want to reset password for{" "}
            <strong>{selectedTeacher?.user.email}</strong>?
          </>
        ),
        confirmButtonText: "Confirm",
      })
    );
  };

  const isDisabled = useMemo(() => {
    return !isHighLevelRole && selectedTeacher?.user.profile.is_active;
  }, [isHighLevelRole, selectedTeacher?.user.profile.is_active]);

  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const handleActiveStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (selectedTeacher?.user.id) {
      dispatch<any>(changeUserStatus(selectedTeacher.user.id, checked)).then(
        () => {
          setLoadingChangeStatus(false);
        },
        (error: string) => {
          toastr.error("Error", error);
          setLoadingChangeStatus(false);
        }
      );
    }
  };
  return (
    <Form id="teacherInfoForm" onSubmit={handleSubmit(renewedTeacherInfo)}>
      {isHighLevelRole && (
        <div className="mb-3 d-flex">
          <h4 className="font-weight-bold mr-2">User Status</h4>
          <span className="list-order-swither-checkbox d-flex">
            <label>Disabled</label>  
            <Form.Check
              type="switch"
              id="active-status-switch"
              label='Active'
              className="ml-2"
              checked={!selectedTeacher?.user.profile.account_disabled}
              onChange={handleActiveStatusChange}
            />
          </span>
          {loadingChangeStatus && (
            <Spinner animation="border" size="sm" className="ml-2" />
          )}
        </div>
      )}

      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>Name</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          required
          type="text"
          name={"first_name"}
          placeholder="First Name"
          onChange={handleTeacherNameChange}
          disabled={isDisabled}
          value={renewedTeacherInfo.first_name}
        />
        <Form.Control
          required
          type="text"
          name={"last_name"}
          placeholder="Last Name"
          onChange={handleTeacherNameChange}
          disabled={isDisabled}
          value={renewedTeacherInfo.last_name}
        />
      </InputGroup>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>Email</InputGroup.Text>
        </InputGroup.Prepend>

        <Form.Control
          required
          type="email"
          name="email"
          disabled={isDisabled}
          value={renewedTeacherInfo.email}
          onChange={handleEmailChange}
        />
        {/* {isHighLevelRole && (
          <InputGroup.Append>
            <InputGroup.Text>
              {loadingResetPassword ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <span
                  className="fa-layers fa-fw pointer"
                  onClick={handlePasswordResetConfirm}
                >
                  <FontAwesomeIcon
                    icon={faUndo}
                    style={{ fontSize: "1.75rem" }}
                  />
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{ fontSize: "0.75rem" }}
                  />
                </span>
              )}
            </InputGroup.Text>
          </InputGroup.Append>
        )} */}
      </InputGroup>

      <h4 className="font-weight-bold">
        Which school(s) does the educator work in?
      </h4>
      <Select
        isMulti
        name="schools"
        value={schools.filter((school) =>
          renewedTeacherInfo.schools.find((rSchool) => rSchool === school.id)
        )}
        getOptionLabel={({ name }: School) => name}
        getOptionValue={(school: School) => school.id.toString()}
        isDisabled={isDisabled}
        options={schools}
        onChange={handleSchoolsChange}
        className="schoolSelect"
        placeholder="Select school(s)..."
      />
      {/* {hasDistrictPermission && (
        <RoleSelector
          selectedRole={renewedTeacherInfo.role}
          onRoleChange={handleRoleChange}
        />
      )} */}
    </Form>
  );
};

const mapStateToProps = ({
  onboarding,
  auth,
}: ApplicationState): StateProps => {
  return {
    hasDistrictPermission:
      auth.userInfo?.profile.current_assignment?.role &&
      districtRoles.includes(auth.userInfo.profile.current_assignment.role),
    schools: onboarding.schools,
    selectedTeacher: onboarding.selectedTeacher,
  };
};

export default connect(mapStateToProps)(TeacherInfoForm);
