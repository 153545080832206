/* global gapi */
/* global google */

import React, { ChangeEvent, Component } from "react";
import { Alert, Form, FormControl, FormGroup, Modal, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  checkEmailExist,
  createUserInDistrict,
  hideCheckEmailExistAlert,
  hideFailedInvitationsAlert,
  hideShareDataModal,
  sendInviteToTeachers,
} from "../../../../../store/onboarding/actions";

import GooglePicker from "react-google-picker";
import { saveAs } from "file-saver";
import LoadingIndicator from "../../../onboarding/LoadingIndicator";
import {
  AddUserToDistrictRequest,
  EmailCheckResponse,
  FailedInvite,
  GradeLevels,
  InvitesResponse,
  ReactSelectOption,
  School,
  SendInviteRequest,
  SuccessfulInvite,
  TeacherInfo,
  UserSpecification,
  UserSpecifications,
} from "../../../../../store/onboarding/types";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { Grade } from "../../../../../store/groups/types";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import FailedInvitationsAlert from "../../../onboarding/second-step/upload-educators/FailedInvitationsAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IS_READY_COACH } from "../../../../../constants";
import { getFullName } from "../../../../../utils/NamesUtils";
import { UserAssignmentRole, UserInfo } from "../../../../../store/auth/types";

const CLIENT_ID =
  "756352255646-h4f73poogn3prec7neqp43tep5pc484p.apps.googleusercontent.com";

const DEVELOPER_KEY = "AIzaSyCFfnK7zUQVQGlQm3fhyIHIfRYCbv7_VoA";
const SCOPE = ["https://www.googleapis.com/auth/drive"];

type OwnProps = {};

type PropsFromState = {
  showModal: boolean;
  callFrom?: string;
  invites: InvitesResponse;
  newInviteData?: SendInviteRequest;
  teachers: TeacherInfo[];
  schools: School[];
  isLoading: {
    resendInviteToTeachers: boolean;
    sendInviteToTeachers: boolean;
    checkEmailExist: boolean;
    addUserToDistrict: boolean;
    resendInviteToUsers: boolean;
  };
  errors: {
    resendInviteToTeachers?: string;
    sendInviteToTeachers?: string;
    checkEmailExist?: string;
    addUserToDistrict?: string;
    resendInviteToUsers?: string;
  };
  districtUserName?: string;
  showFailedInvitationsAlert: boolean;
  existingEmails: EmailCheckResponse,
  userInfo?: UserInfo,
};

type DispatchProps = {
  createUserInDistrict: (invites: AddUserToDistrictRequest[]) => any;
  checkEmailExist: (inviteEmails:Array<any>) => any;
  sendInviteToTeachers: (invites: SendInviteRequest[]) => any;
  hideShareDataModal: () => any;
  hideFailedInvitationsAlert: () => any;
  hideCheckEmailExistAlert: () => any;
};

type Props = OwnProps & PropsFromState & DispatchProps;

enum Content {
  SaveMethod,
  ConnectToGoogleDrive,
  Invite,
  SavingFile,
  FileSaved,
}

type State = {
  currentContent: Content;
  isGooglePickerOpen: boolean;
  isAllData: boolean;
  gradesFilter: Grade[];
  teachersFilter: number[];
  schoolsFilter: number[];
  role?: UserSpecification;
  invites: SendInviteRequest[];
  successful_invites: SuccessfulInvite[];
};

class ShareDataModal extends Component<Props, State> {
  defaultState: Readonly<State> = {
    currentContent: Content.Invite,
    isGooglePickerOpen: false,
    invites: [
      {
        first_name: "",
        last_name: "",
        email: "",
      },
    ],
    successful_invites: [],
    isAllData: false,
    gradesFilter: [],
    teachersFilter: [],
    schoolsFilter: [],
    role: undefined,
  };

  state: Readonly<State> = this.defaultState;

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (prevState.isGooglePickerOpen && !this.state.isGooglePickerOpen) {
      this.setState({ currentContent: Content.SavingFile });
    }
    if (
      prevProps.isLoading.sendInviteToTeachers &&
      !this.props.isLoading.sendInviteToTeachers
    ) {
      if (this.props.errors.sendInviteToTeachers) {
        toastr.error("Error", this.props.errors.sendInviteToTeachers);
      } else {
        const { failed_invites, successful_invites } = this.props.invites;
        if (failed_invites.length > 0) {
          const invites = this.state.invites.filter(
            (invite) =>
              !successful_invites.some(
                (successfulInvite) =>
                  successfulInvite.user.email === invite.email
              )
          );
          this.setState((prevState) => ({
            invites: invites,
            successful_invites: [
              ...prevState.successful_invites,
              ...this.props.invites.successful_invites,
            ],
          }));
        } else {
          if (IS_READY_COACH) {
            this.props.hideFailedInvitationsAlert();
            this.props.hideShareDataModal();
            this.props.hideCheckEmailExistAlert();
          } else {
            this.setState({ currentContent: Content.SaveMethod });
            this.props.hideFailedInvitationsAlert();
          }
        }
      }
    }
  }

  onShowModal = () => {
    const { newInviteData } = this.props;

    const defaultState: Partial<State> = IS_READY_COACH
      ? {
          isAllData: true,
          role: "teacher",
        }
      : {};

    if (newInviteData) {
      // console.log([{ ...newInviteData }]);
      this.setState({
        ...this.defaultState,
        ...defaultState,
        invites: [{ ...newInviteData }],
        gradesFilter: newInviteData.grades ? newInviteData.grades : [],
        teachersFilter: newInviteData.teachers ? newInviteData.teachers : [],
      });
    } else {
      this.setState({ ...this.defaultState, ...defaultState });
    }
  };

  triggerDownloadAsFile = () => {
    const fileName = "NJTSS-ER-Students" + moment().format("(MM.DD HH:mm)");

    const storedJSONString = localStorage.getItem("studentMap");

    let studentsJSON;
    if (storedJSONString) {
      studentsJSON = JSON.parse(storedJSONString);
    } else {
      studentsJSON = {};
    }

    const fileJSON = {
      metadata: { type: "NJTSS-ER-Students-Mapping-File", version: "1.0" },
      mapping: studentsJSON,
    };

    const asString = JSON.stringify(fileJSON);

    const blob = new Blob([asString], {
      type: "application/json;charset=utf-8",
    });

    saveAs(blob, `${fileName}.irkey`);
    this.setState({ currentContent: Content.FileSaved });
  };

  saveMappingFileToFolder = async (folderId: string) => {
    const fileName =
      "NJTSS-ER-Students" + moment().format("(MM.DD HH:mm)") + ".irkey";

    const saveData = async () => {
      await gapi.client.init({
        apiKey: DEVELOPER_KEY,
        clientId: CLIENT_ID,
        scope: SCOPE[0],
      });

      const storedJSONString = localStorage.getItem("studentMap");

      let studentsJSON;
      if (storedJSONString) {
        studentsJSON = JSON.parse(storedJSONString);
      } else {
        studentsJSON = {};
      }

      const fileJSON = {
        metadata: { type: "NJTSS-ER-Students-Mapping-File", version: "1.0" },
        mapping: studentsJSON,
      };

      const fileJSONString = JSON.stringify(fileJSON);

      const file = new Blob([fileJSONString], { type: "application/json" });
      const metadata = {
        name: fileName, // Filename at Google Drive
        mimeType: "application/JSON", // mimeType at Google Drive
        parents: [folderId],
      };

      const accessToken = gapi.auth.getToken().access_token;

      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(metadata)], { type: "application/json" })
      );

      form.append("file", file);

      // const xhr = new XMLHttpRequest();
      // xhr.open(
      //   "POST",
      //   "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id"
      // );

      // xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
      // xhr.responseType = "json";
      // xhr.onload = () => {
      //   this.shareMappingFileInGD(xhr.response.id);
      //   this.setState({ currentContent: Content.FileSaved });
      // };

      // xhr.send(form);
    };

    gapi.load("client", async () => {
      await saveData();
    });
  };

  //share GD file access to invited users
  shareMappingFileInGD = (fileId: string) => {
    const { invites } = this.state;

    const shareData = () => {
      gapi.client.init({
        apiKey: DEVELOPER_KEY,
        clientId: CLIENT_ID,
        scope: SCOPE[0],
      });
      const batch = gapi.client.newBatch();
      for (const invite of invites) {
        const request = gapi.client.drive.permissions.create({
          fileId: fileId,
          // @ts-ignore
          resource: {
            role: "reader",
            type: "user",
            emailAddress: invite.email,
          },
        });
        batch.add(request);
      }
      const onload = (response: any) => {
        console.log(response);
      };
      batch.execute(onload);
    };
    gapi.client.load("drive", "v3", () => {
      shareData();
    });
  };

  handleSaveEvent = async (data: any) => {
    console.log(data);
    if (data.action === "loaded") {
      this.setState({ isGooglePickerOpen: true });
    } else if (data.action === "picked") {
      this.setState({
        isGooglePickerOpen: false,
      });
      const { docs } = data;
      const selectedDocument = docs[0];
      const documentId = selectedDocument.id;

      await this.saveMappingFileToFolder(documentId);
    }
  };

  handleModalHide = () => {
    this.props.hideShareDataModal();
    this.props.hideFailedInvitationsAlert();
    this.props.hideCheckEmailExistAlert();
  };

  handleGradeLevelChange = (
    options: ValueType<ReactSelectOption<Grade>, true>
  ) => {
    if (options && (options as ReactSelectOption<Grade>[]).length > 0) {
      this.setState({
        gradesFilter: (options as ReactSelectOption<Grade>[]).map(
          (v) => v.value
        ),
      });
    } else {
      this.setState({ gradesFilter: [] });
    }
  };

  handleTeacherChange = (options: ValueType<TeacherInfo, true>) => {
    if (options && (options as TeacherInfo[]).length > 0) {
      this.setState({
        teachersFilter: (options as TeacherInfo[]).map((t) => t.user.id),
      });
    } else {
      this.setState({ teachersFilter: [] });
    }
  };

  handleSchoolChange = (options: ValueType<School, true>) => {
    if (options && (options as School[]).length > 0) {
      this.setState({
        schoolsFilter: (options as School[]).map((sc) => sc.id),
      });
    } else {
      this.setState({ schoolsFilter: [] });
    }
  };

  handleUserSpecificationChange = (
    options: ValueType<ReactSelectOption<UserSpecification>, false>
  ) => {
    if (options && (options as ReactSelectOption<UserSpecification>)) {
      this.setState({
        role: (options as ReactSelectOption<UserSpecification>).value,
      });
    } else {
      this.setState({ role: undefined });
    }
  };

  handleChange = (invite: SendInviteRequest) => (
    event: React.FormEvent<any>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    this.setState((prevState) => ({
      ...prevState,
      invites: prevState.invites.map((inv) =>
        inv === invite ? { ...inv, [name]: value } : inv
      ),
    }));
  };

  removeInvite = (index: number) => () => {
    this.setState((prevState) => ({
      ...prevState,
      invites: prevState.invites.filter((inv, i) => i !== index),
    }));
  };

  handleChangeAllData = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      isAllData: checked,
    }));
  };

  onAdd = (event: React.FormEvent) => {
    event.preventDefault();
    this.setState((prevState) => ({
      ...prevState,
      invites: [
        ...prevState.invites,
        {
          first_name: "",
          last_name: "",
          email: "",
        },
      ],
    }));
  };

  onSend = (event: React.FormEvent) => {
    event.preventDefault();
    const {
      isAllData,
      gradesFilter,
      teachersFilter,
      schoolsFilter,
      role,
      invites,
    } = this.state;
    const { isLoading } = this.props;
    if (!isLoading.sendInviteToTeachers) {
      if(this.props.userInfo?.profile.current_assignment?.role 
          === UserAssignmentRole.DISTRICT_OWNER) {
        const inviteEmails = invites.map((invite) => invite.email);     
        this.props.checkEmailExist(inviteEmails)
        .then((result:EmailCheckResponse) => {
            if(result.new_emails.length) {
              const request: AddUserToDistrictRequest[] = invites.map((inv) => ({
                existing_user_id: inv.originalUser?.id,
                first_name: inv?.first_name,
                last_name: inv?.last_name,
                email: inv.email,
                role: UserAssignmentRole.NJTSS_IMPORTED_USER
              }));  
              this.props.createUserInDistrict(request).then(
                () => {
                  toastr.success(
                    "Success",
                    `The invitation has been sent successfully.`
                    );
                    this.handleModalHide();
                },
                (err: any) =>  toastr.error("Failed to send the invite", err)
                  //     //  todo handle failed invites
              );               
            }
          },
          (err: any) =>  toastr.error("Failed to send the invite", err)
        )
      } else {
        const request: SendInviteRequest[] = invites.map((inv) => ({
          ...inv,
          existing_user_id: inv.originalUser?.id,
          all_data: isAllData,
          grades: isAllData ? undefined : gradesFilter,
          teachers: isAllData ? undefined : teachersFilter,
          schools: isAllData ? undefined : schoolsFilter,
          role: UserAssignmentRole.NJTSS_IMPORTED_USER,
        }));
        this.props.sendInviteToTeachers(request).then(
          (invitesResponse: InvitesResponse) => {
          if(!this.props.showFailedInvitationsAlert
            && !this.props.errors?.sendInviteToTeachers
            && !invitesResponse.failed_invites.length){
            toastr.success(
              "Success",
              `You have invited new user(s) to ReadyCoach. Your district admin (${this.props.districtUserName}) has been contacted to establish data accessing settings for the invited user to access shared data.`
            );
            this.handleModalHide();
          }
          },
          (err: string) => toastr.error("Failed to send the invite", err)
        );
      }

    }
  };

  getTeacherOriginalName = (invite: SendInviteRequest) => {
    if (invite.existing_user_id) {
      return <h3 className="w-100 mr-2">{getFullName(invite.originalUser)}</h3>;
    }
    return <i className="w-100 mr-2">n/a</i>;
  };

  getModalBody = () => {
    const getTeacherOptions = () => {
      if (this.state.gradesFilter.length === 0) {
        return this.props.teachers;
      } else {
        return this.props.teachers.filter(
          (t) =>
            !t.user.available_grades ||
            t.user.available_grades.some((ag) =>
              this.state.gradesFilter.includes(ag)
            )
        );
      }
    };
    switch (this.state.currentContent) {
      case Content.Invite:
        return (
          <>
            <h2 className="text-center font-weight-bold">
            {this.props.callFrom == 'inviteFromFile' 
              ? `Enter the first name, last name and email of the user.` 
              : `With whom do you want to share your data?`}  
            </h2>

            <Form
              id="invitesForm"
              onSubmit={this.onSend}
              className="dataShareInviteForm"
            >
              <div className="row mb-1" style={{ marginRight: 15 }}>
                <div className="col font-weight-bold">From Data File</div>
                <div className="col font-weight-bold">First Name</div>
                <div className="col font-weight-bold">Last Name</div>
                <div className="col font-weight-bold">Email</div>
              </div>
              {this.state.invites.map((invite, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center mb-3"
                >
                  {this.getTeacherOriginalName(invite)}
                  <FormGroup
                    controlId="validation_first_name"
                    className="w-100 mr-2 mb-0"
                  >
                    <FormControl
                      required
                      name={"first_name"}
                      placeholder="Enter first name..."
                      type="string"
                      value={invite.first_name}
                      onChange={this.handleChange(invite)}
                    />
                  </FormGroup>
                  <FormGroup
                    controlId="validation_last_name"
                    className="w-100 mr-2 mb-0"
                  >
                    <FormControl
                      required
                      name={"last_name"}
                      placeholder="Enter last name..."
                      type="string"
                      value={invite.last_name}
                      onChange={this.handleChange(invite)}
                    />
                  </FormGroup>
                  <FormGroup
                    controlId="validation_email"
                    className="w-100 mr-2 mb-0"
                  >
                    <FormControl
                      required
                      name={"email"}
                      placeholder="Enter email..."
                      type="email"
                      value={invite.email}
                      onChange={this.handleChange(invite)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Incorrect email.
                    </Form.Control.Feedback>
                  </FormGroup>
                  {!invite.existing_user_id ?  
                    index === 0 ? (
                      <div className="dataShareInviteActionBtn">
                        <button className="blueBtnSm" onClick={this.onAdd}>
                          Add
                        </button>
                      </div>
                    ) : (
                      <div
                        className="dataShareInviteActionBtn"
                        onClick={this.removeInvite(index)}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="redIcon pointer"
                          size="2x"
                        />
                      </div>
                    ) : ''}
                </div>
              ))}
            </Form>

            {!IS_READY_COACH && (
              <>
                <h2 className="text-center font-weight-bold">
                  And which data do you want to share?
                </h2>
                <h4 className="text-center">
                  You can share data for students that are assigned to specific
                  teachers and/or grades, or share all of your data.
                </h4>

                <div className="d-flex flex-column m-3">
                  <div className="text-center">
                    <input
                      readOnly
                      type="checkbox"
                      className="pointer"
                      name="isAllData"
                      checked={this.state.isAllData}
                      onChange={this.handleChangeAllData}
                    />{" "}
                    <span className="font-weight-bold mr-2">
                      SHARE ALL DATA
                    </span>
                  </div>
                  <div className="w-100 mb-2">
                    <span className="font-weight-bold">Grade-level data:</span>
                    <Select
                      isMulti
                      isClearable
                      isDisabled={this.state.isAllData}
                      className="dataFiltersSelect"
                      value={GradeLevels.filter((gl) =>
                        this.state.gradesFilter.includes(gl.value)
                      )}
                      options={GradeLevels}
                      onChange={this.handleGradeLevelChange}
                    />
                  </div>
                  <div>
                    <span className="font-weight-bold mb-2">
                      Specific teachers' student data:
                    </span>
                    <Select
                      isMulti
                      isClearable
                      isDisabled={this.state.isAllData}
                      className="dataFiltersSelect"
                      value={this.props.teachers.filter((t) =>
                        this.state.teachersFilter.includes(t.user.id)
                      )}
                      options={getTeacherOptions()}
                      getOptionLabel={({ user }: TeacherInfo) =>
                        `${user.first_name} ${user.last_name}`
                      }
                      getOptionValue={({ user }: TeacherInfo) =>
                        user.id!.toString()
                      }
                      onChange={this.handleTeacherChange}
                    />
                  </div>
                  <div className="w-100">
                    <span className="font-weight-bold">Specific schools:</span>
                    <Select
                      isMulti
                      isClearable
                      isDisabled={this.state.isAllData}
                      className="dataFiltersSelect"
                      value={this.props.schools.filter((x) =>
                        this.state.schoolsFilter.includes(x.id)
                      )}
                      options={this.props.schools}
                      getOptionLabel={(school: School) => school.name}
                      getOptionValue={(school: School) => school.id.toString()}
                      onChange={this.handleSchoolChange}
                    />
                  </div>
                  <div className="w-100">
                    <span className="font-weight-bold">Kind of user:</span>
                    <Select
                      isClearable
                      className="dataFiltersSelect"
                      value={UserSpecifications.find(
                        (us) => this.state.role === us.value
                      )}
                      options={UserSpecifications}
                      onChange={this.handleUserSpecificationChange}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="m-3">
              {this.props.userInfo?.profile.current_assignment?.role == UserAssignmentRole.DISTRICT_OWNER
               ?
                  !this.props.isLoading.checkEmailExist
                  && !this.props.errors.checkEmailExist
                  ? <Alert
                    show={!!this.props.existingEmails.emails_already_exist.length}
                    variant="danger"
                    onClose={this.props.hideFailedInvitationsAlert}
                    dismissible
                  >
                  <Alert.Heading>Invitation has failed.</Alert.Heading>
                  {this.props.existingEmails.emails_already_exist.map((failedInvite, index) => (
                    <h3 key={index}>
                      <b className="font-weight-bold">{failedInvite}</b>
                      <br/>
                      {" "}
                      <strong>email: </strong> You've already invited this user. <br />
                    </h3>
                  ))}
                </Alert> : ''
              :
                <FailedInvitationsAlert />
              }
            </div>

            <div className="text-center">
              <button
                className="blueBtnMd mt-2"
                form="invitesForm"
                type="submit"
                disabled={((this.props.isLoading.sendInviteToTeachers && !this.props.isLoading.resendInviteToTeachers)
                  || this.props.isLoading.checkEmailExist
                  || (this.props.isLoading.addUserToDistrict && !this.props.isLoading.resendInviteToUsers)
                )}
              >
                Send{" "}
                {((this.props.isLoading.sendInviteToTeachers && !this.props.isLoading.resendInviteToTeachers) 
                || this.props.isLoading.checkEmailExist
                || (this.props.isLoading.addUserToDistrict && !this.props.isLoading.resendInviteToUsers)
              ) && (
                  <Spinner animation="border" size="sm" />
                )}
              </button>
            </div>
          </>
        );
      case Content.SaveMethod: {
        let isFileSaverSupported = false;
        try {
          isFileSaverSupported = !!new Blob();
        } catch (e) {
          //todo ???
        }

        return (
          <>
            <h2 className="text-center font-weight-bold">
              NJTSS Early Reading does not store student names on its servers.
              In order to share, you must also provide other users access to
              your local key.
              <br />
              <br />
              You can download it and share the key manually (email or
              thumbdrive).
            </h2>
            <div className="spaceBetween mt-4 mergeDialogButtons">
              {isFileSaverSupported && (
                <button
                  onClick={this.triggerDownloadAsFile}
                  className="blueBtnMd"
                >
                  Download
                </button>
              )}
              {/* <button
                onClick={() =>
                  this.setState({
                    currentContent: Content.ConnectToGoogleDrive
                  })
                }
                className="blueBtnMd"
              >
                Google Drive
              </button>*/}
            </div>
          </>
        );
      }
      case Content.ConnectToGoogleDrive:
        return (
          <>
            <h2 className="text-center font-weight-bold">
              Next, tell us which folder to save your key into. The users you
              invite to share with will need access to the location folder.
            </h2>
            <div className="spaceBetween mt-4 mergeDialogButtons">
              <GooglePicker
                clientId={CLIENT_ID}
                developerKey={DEVELOPER_KEY}
                scope={SCOPE}
                onChange={(data) => this.handleSaveEvent(data)}
                onAuthFailed={(data) => console.log("on auth failed:", data)}
                createPicker={(google: any, oauthToken: string) => {
                  const googleViewId = google.picker.ViewId.FOLDERS;
                  const docsView = new google.picker.DocsView(googleViewId)
                    .setIncludeFolders(true)
                    .setMimeTypes("application/vnd.google-apps.folder")
                    .setSelectFolderEnabled(true);

                  const picker = new google.picker.PickerBuilder()
                    .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
                    .addView(docsView)
                    .setOAuthToken(oauthToken)
                    .setDeveloperKey(DEVELOPER_KEY)
                    .setCallback((data: any) => this.handleSaveEvent(data));

                  picker.build().setVisible(true);
                }}
                multiselect={false}
                navHidden={false}
                authImmediate={false}
                viewId={"DOCS"}
              >
                <button className="blueBtnLg">Connect to Google Drive</button>
              </GooglePicker>
            </div>
          </>
        );
      case Content.SavingFile:
        return <LoadingIndicator />;
      case Content.FileSaved:
        return (
          <>
            <h2 className="text-center font-weight-bold">
              A file will download to your downloads folder. You will need to
              retrieve this file from this folder and transfer it to the person
              with whom you are sharing data either by a thumb drive, email, or
              a cloud drive (e.g. Google Drive). This person will then be
              instructed to upload it from within the app.
            </h2>
            <div className="spaceBetween mt-4 mergeDialogButtons">
              <button onClick={this.handleModalHide} className="blueBtnMd">
                Got It
              </button>
            </div>
          </>
        );
    }
  };

  render() {
    const { showModal } = this.props;
    return (
      <Modal
        show={showModal && !this.state.isGooglePickerOpen}
        animation={false}
        size="lg"
        backdropClassName="customDarkModalBackdrop in"
        onHide={this.handleModalHide}
        onShow={this.onShowModal}
      >
        <Modal.Header
          closeButton
          className={IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"}
        >
          <Modal.Title className="modalTitle">{this.props.callFrom == 'inviteFromFile' ? `Invite User`:`Share Data`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.getModalBody()}</Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth, onboarding }: ApplicationState): PropsFromState => {
  return {
    showModal: onboarding.modalsState.shareDataModal,
    callFrom: onboarding.callFrom,
    invites: onboarding.invites,
    newInviteData: onboarding.newInviteData,
    teachers: onboarding.teachersRoster,
    schools: onboarding.schools,
    districtUserName: auth.userInfo?.profile.district?.owner.first_name + " " + auth.userInfo?.profile.district?.owner.last_name,
    isLoading: {
      resendInviteToTeachers: onboarding.isLoading.resendInviteToTeachers,
      sendInviteToTeachers: onboarding.isLoading.sendInviteToTeachers,
      checkEmailExist: onboarding.isLoading.checkEmailExist,
      addUserToDistrict: onboarding.isLoading.addUserToDistrict,
      resendInviteToUsers: onboarding.isLoading.resendInviteToUsers,
    },
    errors: {
      resendInviteToTeachers: onboarding.errors.resendInviteToTeachers,
      sendInviteToTeachers: onboarding.errors.sendInviteToTeachers,
      checkEmailExist: onboarding.errors.checkEmailExist,
      addUserToDistrict: onboarding.errors.addUserToDistrict,
      resendInviteToUsers: onboarding.errors.resendInviteToUsers,
    },
    showFailedInvitationsAlert: onboarding.modalsState.showFailedInvitationsAlert,
    userInfo: auth.userInfo,
    existingEmails: onboarding.existingEmails
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      createUserInDistrict: createUserInDistrict,
      checkEmailExist: checkEmailExist,
      sendInviteToTeachers: sendInviteToTeachers,
      hideShareDataModal: hideShareDataModal,
      hideFailedInvitationsAlert: hideFailedInvitationsAlert,
      hideCheckEmailExistAlert: hideCheckEmailExistAlert,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShareDataModal);
