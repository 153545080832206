import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import {
  DataSharingTableData,
  DistrictAccessDataSetting,
  InviteStatuses,
  School,
  SendInviteRequest,
  TeacherInfo,
} from "../../../../../../store/onboarding/types";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import LoadingIndicator from "../../../LoadingIndicator";
import DataSharingEditModal from "../../../../reading-interventions/educators-and-sharing/modals/DataSharingEditModal";
import InviteTeachersModal from "../../../../reading-interventions/educators-and-sharing/modals/InviteTeachersModal";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import {
  changeSelectedTeacher,
  changeStudentsFilter,
  deleteDataSharingTableData,
  getDataSharingTableData,
  getDistrictAccessDataSettings,
  getSchools,
  getTeachers,
  openDataSharingEditModal,
  openDistrictAccessDataSettingUpsertModal,
  openShareDataModal,
  openTeacherDetailsModal,
  setNewInviteData,
} from "../../../../../../store/onboarding/actions";
import { useLoading } from "../../../../../../utils/hooks/useLoading";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";
import { getInviteStatusDisplay } from "../../../../../../utils/NamesUtils";
import DistrictAccessDataSettingUpsertModal from "../../../../../pages/district-owner-dashboard/tabs/data-access/DistrictAccessDataSettingUpsertModal";
import { UserInfo } from "../../../../../../store/auth/types";
import ResendInviteButton from "../../../../../pages/district-owner-dashboard/tabs/your-staff/manage-users-page/ResendInviteButton";

type OwnProps = {};

type Props = OwnProps;

type MergedData = {
  dataSharing: DataSharingTableData;
  teacher?: TeacherInfo;
};

const MyTeachersTable: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const isLoadingSendInviteToTeachers = useSelector<ApplicationState, boolean>(
    (s) => s.onboarding.isLoading.sendInviteToTeachers
  );

  const loadingTeachersList = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.onboarding.isLoading.getTeachers);

  const loadingDataSharingList = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.onboarding.isLoading.getDataSharingTableData);

  const errorSendInviteToTeachers = useSelector<
    ApplicationState,
    string | undefined
  >((s) => s.onboarding.errors.sendInviteToTeachers);
  useLoading(isLoadingSendInviteToTeachers, errorSendInviteToTeachers, () =>{
    dispatch(getDataSharingTableData())
  }
  ); 


  const tableData = useSelector<ApplicationState, DataSharingTableData[]>(
    (s) => s.onboarding.dataSharingTableData
  );
  let teachers = useSelector<ApplicationState, TeacherInfo[]>(
    (s) => s.onboarding.teachersRoster
  );

  const teachersFilter = useSelector<ApplicationState, string>(
    (s) => s.onboarding.teachersFilter
  );
  
  const firstUpdate = useRef(true);
  useEffect(()=> {
    if(firstUpdate.current){
      dispatch(getDataSharingTableData())
      firstUpdate.current = false;
      return;
    }
  },[teachers])

  const mergedData: MergedData[] = useMemo(() => {
    let mergeTableData =  
        tableData.map((dataSharing) => ({
              dataSharing: dataSharing,
              teacher: teachers.find((t) => dataSharing.user.id === t.user.id),
            }));
        
        if(mergeTableData.length) {
          mergeTableData = [...mergeTableData.filter((mergeData) => 
                    mergeData.dataSharing.user.first_name
                      .toLowerCase()
                      .includes(teachersFilter.toLowerCase()) ||
                    mergeData.teacher?.user.first_name
                      .toLowerCase()
                      .includes(teachersFilter.toLowerCase()) ||
                    mergeData.dataSharing.user.last_name
                      .toLowerCase()
                      .includes(teachersFilter.toLowerCase()) ||
                    mergeData.teacher?.user.last_name
                      .toLowerCase()
                      .includes(teachersFilter.toLowerCase()) ||
                    mergeData.dataSharing.user.email
                      .toLowerCase()
                      .includes(teachersFilter.toLowerCase()) ||
                    mergeData.teacher?.user.email
                      .toLowerCase()
                      .includes(teachersFilter.toLowerCase()) ||
                    mergeData.teacher?.invite.status
                      .toLowerCase()
                      .includes(teachersFilter.toLowerCase())
            )];
        } 
      return mergeTableData;     
  }, [tableData, teachers, teachersFilter]);

  const isEmpty = useMemo(() => {
    if(loadingDataSharingList || loadingTeachersList) {
      return <LoadingIndicator />
    } else if(mergedData.length === 0 && !loadingTeachersList) {
      return "No matching teachers"; 
    } 
  },[mergedData, loadingTeachersList, loadingDataSharingList])

  const columnEvents = {
    onClick: (
      e: React.MouseEvent,
      column: any,
      columnIndex: number,
      row: DataSharingTableData
    ) => {
      const selectedData = tableData.find((data) => data.id === row.id);
      if (selectedData) {
        const selectedTeacher = teachers.find(
          (teacher) => teacher.user.id === selectedData.user.id
        );
        if (selectedTeacher) {
          dispatch(changeSelectedTeacher(selectedTeacher));
          dispatch(openTeacherDetailsModal());
        }
      }
    },
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "date",
      text: "Date",
      hidden: true,
    },
    {
      dataField: "user",
      text: "User",
      sort: true,
      events: columnEvents,
      headerStyle: { width: "12%" }
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      events: columnEvents,
      headerStyle: { width: "18%" }
    },
    {
      dataField: "school",
      text: "School",
      sort: true,
      events: columnEvents,
      headerStyle: { width: "20%" }
    },
    {
      dataField: "numClasses",
      text: "# of Classes",
      sort: true,
      events: columnEvents,
      headerStyle: { width: "13%" }
    },
    {
      dataField: "numStudents",
      text: "# of Students",
      sort: true,
      events: columnEvents,
      headerStyle: { width: "13%" }
    },
    {
      dataField: "inviteStatus",
      text: "Invite Status",
      sort: true,
      headerStyle: { width: "14%" }
    },
    {
      dataField: "actions",
      text: "Actions ",
      sort: false,
      headerStyle: { width: "10%"},
      headerAlign: 'right'
    },
  ];

  const defaultSorted: [{ dataField: string; order: SortOrder }] = useMemo(
    () => [
      {
        dataField: "user",
        order: "asc",
      },
    ],
    []
  );

  const options = useMemo(
    () => ({
      sizePerPageList: [
        {
          text: "20",
          value: 20,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
    }),
    []
  );

  
  
  useEffect(() => {
    dispatch(getSchools());
    if(!teachers.length) {
      dispatch(getTeachers());
    }
    dispatch(getDistrictAccessDataSettings());
  }, []);

  const schools = useSelector<ApplicationState, School[]>(
    (s) => s.onboarding.schools
  );
  const districtAccessDataSettings = useSelector<
    ApplicationState,
    DistrictAccessDataSetting[]
  >((s) => s.onboarding.districtAccessDataSettings);

  const [selectedUser, setSelectedUser] = useState<
    DataSharingTableData | undefined
  >();

  // const handleEditRow = (selectedUser: DataSharingTableData) => {
  //   setSelectedUser(selectedUser);
  //   dispatch(openDataSharingEditModal());
  // };

  const handleView = (
    educator: UserInfo,
    setting?: DistrictAccessDataSetting
  ) => {
    dispatch(
      openDistrictAccessDataSettingUpsertModal(
        setting ? { ...(setting as any), user: educator } : { user: educator }
      )
    );
  };

  const handleDeleteRow = (row: DataSharingTableData) => {
    const onConfirm = () => {
      dispatch(deleteDataSharingTableData(row));
    };
    dispatch(showConfirmDialog({ onConfirm, centered: true }));
  };

  const handleInviteFromFile = (data: DataSharingTableData) => () => {
    const invite: SendInviteRequest = {
      first_name: data.user.first_name,
      last_name: data.user.last_name,
      email: "",
      existing_user_id: data.user.id,
      originalUser: data.user,
      teachers: [...data.teachers.map((t) => t.id), data.user.id],
      grades: data.grades,
    };
    dispatch(setNewInviteData(invite));
    dispatch(openShareDataModal('inviteFromFile'));
  };

  const getEmailDisplay = (email?: string) => {
    if (!email) {
      return null;
    }
    if (email.indexOf("njtss_user_imported") >= 0) {
      return <em>Imported From File</em>;
    } else {
      return <span>{email}</span>;
    }
  };

  const showSchools = (teacher: TeacherInfo) => {
    if (teacher.user.profile.current_assignment) {
      const schoolsNames: Array<String> = teacher.user.profile.current_assignment!.schools.map(
        (school) => school.name
      );
      if (schoolsNames) {
        return schoolsNames.join(", ");
      }
    }
    return "";
  };

  return (
    <div className={` ${mergedData.length ? 'noOverflow' : ''}`}>
      <BootstrapTable
        classes={`not-fixed rosterTable`}
        wrapperClasses={tableData.length === 0 ? "rosterTableEmpty" : ""}
        keyField="id"
        bordered={false}
        bootstrap4
        data={mergedData.map(({ dataSharing: row, teacher }) => { 
          const settings = districtAccessDataSettings.find(
            (s) => s.user === teacher?.user.id
          );
        return {
          id: row.id,
          date: row.updated,
          user: `${row.user.first_name} ${row.user.last_name}`,
          email: <div className="multiline-break">{getEmailDisplay(teacher?.user.email)}</div>,
          school: teacher ? showSchools(teacher) : null,
          numClasses: teacher ? teacher.info.classes : null,
          numStudents: teacher ? teacher.info.students : null,
          inviteStatus: (
            <>
              {teacher && teacher.invite && teacher.invite.status !== "invite_pending" ? (
                <div>{getInviteStatusDisplay(teacher)}</div>
              ) : (
                <div>
                  <div
                    className="tableRowBtn"  style={{width: "100px"}}
                    onClick={handleInviteFromFile(row)}
                  >
                    Click to Invite.
                  </div>
                </div>
              )}
            </>
          ),
          actions: (
            <div className="row float-right">
              <div className="col-2">
                  <ResendInviteButton
                    isPending={getInviteStatusDisplay(teacher!).replace(' ','_').toLowerCase() == InviteStatuses.INVITE_SENT}
                    employee={teacher!}
                  />
              </div>
              <div className="col-2">
              <OverlayTrigger
                overlay={
                  <Tooltip id="deleteIcon" className="customInfoTooltip">
                    View Data Sharing Access
                  </Tooltip>
                }
              >
                <span onClick={() => handleView(teacher?.user! ,settings)}>
                  <FontAwesomeIcon icon={faEye} />
                </span>
              </OverlayTrigger>
            </div>
            <div className="col-2">
              <OverlayTrigger
                overlay={
                  <Tooltip id="deleteIcon" className="customInfoTooltip">
                    Delete
                  </Tooltip>
                }
              >
                <span onClick={() => handleDeleteRow(row)}>
                  <FontAwesomeIcon icon={faTrash} />
                </span>
              </OverlayTrigger>
            </div>
            </div>
          ),
        }})}
        columns={columns}
        defaultSorted={defaultSorted}
        noDataIndication={isEmpty}
        pagination={paginationFactory(options)}
      />
      {/* <DataSharingEditModal selectedUser={selectedUser} /> */}
      
      <InviteTeachersModal />
    </div>
  );
};

export default MyTeachersTable;
