import React, { FunctionComponent } from "react";
import { UserInfo } from "../../../store/auth/types";
import { FbaObservation } from "../../../store/onboarding/cases/types";
import { RouteComponentProps } from "react-router";
import { Container, Nav, Tab } from "react-bootstrap";
import UserAvatarSmall from "../../common/UserAvatarSmall";
import LoadingIndicator from "../../common/onboarding/LoadingIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faChalkboardTeacher,
  faDatabase,
  faFileAlt,
  faTable,
  faUserLock,
  faUsers,
  faUserCheck,
  faFileUpload,
  faBell,
  faRestroom,
  faVideo,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import YourStaffTab from "./tabs/your-staff/YourStaffTab";
import SchoolsManagementTab from "./tabs/schools/SchoolsManagementTab";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import ChatModerationTab from "./tabs/chat-moderation/ChatModerationTab";
import AssessmentLibraryTab from "./tabs/assessment-library/AssessmentLibraryTab";
import InterventionLibraryTab from "../coach-dashboard/tabs/explore-and-learn/intervention-library/InterventionLibraryTab";
import UpsertSchoolModal from "./tabs/schools/UpsertSchoolModal";
import DistrictSummaryTab from "./tabs/district-summary/tables/DistrictSummaryTab";
import CreateNewInterventionModal from "../coach-dashboard/tabs/explore-and-learn/intervention-library/new-intervention-modal/NewInterventionModal";
import YourDataAndInterviewsTab from "../coach-dashboard/tabs/your-data-and-interviews/YourDataAndInterviewsTab";
import ClassWideNeedsModal from "../coach-dashboard/tabs/your-data-and-interviews/class-wide-needs-modal/ClassWideNeedsModal";
import ConfirmRecommendModal from "../../common/reading-interventions/intervention-tools/data-tab/modals/ConfirmRecommendModal";
import ConfirmDiagnosisModal from "../../common/reading-interventions/intervention-tools/data-tab/modals/ConfirmDiagnosisModal";
import ManageDataPeriodsModal from "../coach-dashboard/tabs/your-data-and-interviews/manage-data-periods/ManageDataPeriodsModal";
import DataAccessPage from "./tabs/data-access/DataAccessPage";
import useUserRole from "../../../utils/hooks/useUserRole";
import ConfirmDialog from "../../common/confirm-dialog/ConfirmDialog";
import EquityBenchmarksModal from "../coach-dashboard/tabs/your-data-and-interviews/equity-benchmarks-modal/EquityBenchmarksModal";
import InterventionLibraryModal from "../../common/onboarding/third-step/find-intervention/InterventionLibraryModal";
import ClassesTab from "./tabs/classes/ClassesTab";
import EditProfileModal from "../../common/reading-interventions/management/profile-tab/modals/EditProfileModal";
import UploadDistrictPage from "./tabs/upload-district-data/UploadDistrictPage";
import ProfileTab from "../../common/reading-interventions/management/profile-tab/ProfileTab";
import ChangePasswordModal from "../../common/reading-interventions/management/profile-tab/modals/ChangePasswordModal";
import NotificationsTab from "../../common/reading-interventions/management/notifications-tab/NotificationsTab";
import RostersTab, { SecondStepTabKeys } from "../../common/onboarding/second-step/rosters-tab/RostersTab";
import TeacherDetailsModal from "../../common/onboarding/second-step/teacher-details/TeacherDetailsModal";
import ShareDataModal from "../../common/reading-interventions/educators-and-sharing/modals/ShareDataModal";
import InterventionPlanModal from "../coach-dashboard/tabs/your-assigments/assigment/intervention-plan-modal/InterventionPlanModal";
import UploadStudentsRosterModal from "../../../containers/onboarding/second-step/students/UploadStudentsRosterModal";
import { useIntl } from "react-intl";
import TutorialVideosPage from "../tutorial_videos/TutorialVideosPage";
import ReleaseNotesTab from "../release-notes/ReleaseNotesTab";
import { EventKey } from "react-bootstrap/esm/types";

export enum DistrictOwnerDashboardTabKeys {
  YOUR_STAFF = "staff",
  MANAGE_SCHOOLS = "schools",
  MANAGE_CLASSES = "classes",
  MANAGE_DISTRICT_DATA = "district-data",
  MANAGE_DATA_ACCESS = "data-access",
  UPLOAD_DISTRICT_DATA = "upload-district-data",
  CHAT_MODERATION = "chat-moderation",
  ASSESSMENT_LIBRARY = "assessments",
  INTERVENTION_LIBRARY = "interventions",
  DISTRICT_SUMMARY = "summary",
  NOTIFICATION_TAB = "settings/notifications",
  SETTINGS = "",
  YOUR_PROFILE = "profile",
  ROSTERS_STUDENTS = "students",
  TUTORIAL_VIDEOS = "tutorials/videos",
  RELEASE_NOTES = "release-notes",
  //RELEASE_NOTES_DETAIL = "release-notes-detail",

}

type DispatchProps = {
  push: (location: string) => any;
};

type PropsFromState = {
  userInfo?: UserInfo;
  selectedFbaObservation?: FbaObservation;
  showClassWideNeedsModal?: boolean;
};

type MatchParams = {
  tabName: string;
  id?: string;
};

type Props = PropsFromState &
  RouteComponentProps<MatchParams> &
  DispatchProps & {
    getUserInfo: () => any;
  };

const DistrictOwnerDashboard: FunctionComponent<Props> = ({
  userInfo,
  match: {
    params: { tabName: activeTab, id },
  },
  push,
  showClassWideNeedsModal
}) => {
  const handleActiveTabSelect = (newActiveTab: string | null) => {
    if (newActiveTab) {
      push(`/district/${newActiveTab}`);
    }
  };

  const { isDataManager, isDistrictOwner } = useUserRole();
  const intl = useIntl();


  return (
    <Container fluid>
      <Tab.Container
        mountOnEnter
        unmountOnExit
        defaultActiveKey={DistrictOwnerDashboardTabKeys.YOUR_STAFF}
        activeKey={activeTab as EventKey}
        onSelect={handleActiveTabSelect}
      >
        <div className="my-4 d-flex" id="user-dashboard">
          <div
            className="coachDashboardSidebar mr-3"
            id="user-dashboard-sidebar"
          >
            {userInfo ? (
              <div className="coachDashboardUserInfo">
                <UserAvatarSmall size={60} userInfo={userInfo} />
                <h3>{`${userInfo.first_name} ${userInfo.last_name}`}</h3>
                <h4>{userInfo.profile.district?.name}</h4>
              </div>
            ) : (
              <LoadingIndicator />
            )}
            <hr />
            <Nav className="flex-column justify-content-start">
              <h4 className="font-weight-bold">Your District</h4>
              <Nav.Item>
                <Nav.Link eventKey={DistrictOwnerDashboardTabKeys.YOUR_STAFF}>
                  <FontAwesomeIcon icon={faUsers} />
                  &nbsp;Your Staff
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey={DistrictOwnerDashboardTabKeys.DISTRICT_SUMMARY}
                >
                  <FontAwesomeIcon icon={faTable} />
                  &nbsp;District Summary
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey={DistrictOwnerDashboardTabKeys.MANAGE_SCHOOLS}
                >
                  <FontAwesomeIcon icon={faBuilding} />
                  &nbsp;Manage Schools
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link
                  eventKey={DistrictOwnerDashboardTabKeys.MANAGE_CLASSES}
                >
                  <FontAwesomeIcon icon={faChalkboardTeacher} />
                  &nbsp;Manage Classes
                </Nav.Link>
              </Nav.Item> */}
              {isDataManager || isDistrictOwner && (
                <Nav.Item>
                  <Nav.Link
                    eventKey={
                      DistrictOwnerDashboardTabKeys.MANAGE_DISTRICT_DATA
                    }
                  >
                    <FontAwesomeIcon icon={faTable} />
                    &nbsp;Manage District Data
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link
                  eventKey={DistrictOwnerDashboardTabKeys.MANAGE_DATA_ACCESS}
                >
                  <FontAwesomeIcon icon={faUserLock} />
                  &nbsp;Manage Data Permissions
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link
                  eventKey={DistrictOwnerDashboardTabKeys.UPLOAD_DISTRICT_DATA}
                >
                  <FontAwesomeIcon icon={faFileUpload} />
                  &nbsp;Upload District Data
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link
                  eventKey={DistrictOwnerDashboardTabKeys.ROSTERS_STUDENTS}
                >
                  <FontAwesomeIcon icon={faRestroom} />
                  &nbsp;Student Data
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link
                  eventKey={DistrictOwnerDashboardTabKeys.CHAT_MODERATION}
                >
                  <FontAwesomeIcon icon={faComments} />
                  &nbsp;Chat Moderation
                </Nav.Link>
              </Nav.Item> */}
              <h4 className="font-weight-bold">Explore & Learn</h4>
              {/* <Nav.Item>
                <Nav.Link
                  eventKey={DistrictOwnerDashboardTabKeys.ASSESSMENT_LIBRARY}
                >
                  <FontAwesomeIcon icon={faFileAlt} />
                  &nbsp;Assessment Library
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link
                  eventKey={DistrictOwnerDashboardTabKeys.INTERVENTION_LIBRARY}
                >
                  <FontAwesomeIcon icon={faDatabase} />
                  &nbsp;Intervention Library
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
              <Nav.Link eventKey={DistrictOwnerDashboardTabKeys.TUTORIAL_VIDEOS}>
                <FontAwesomeIcon icon={faVideo} />
                &nbsp;
                {intl.formatMessage({
                  id: "app.navigation.dashboardMenuPanel.videoTutorialLabel",
                })}
              </Nav.Link>
               </Nav.Item>
               <Nav.Item>
                <Nav.Link
                  eventKey={DistrictOwnerDashboardTabKeys.RELEASE_NOTES}
                >
                  <FontAwesomeIcon icon={faStickyNote} />
                  &nbsp;Release Notes
                </Nav.Link>
              </Nav.Item>
              <h4 className="font-weight-bold">Management</h4>
              <Nav.Item>
                <Nav.Link eventKey={DistrictOwnerDashboardTabKeys.YOUR_PROFILE}>
                  <FontAwesomeIcon icon={faUserCheck} />
                  &nbsp;
                  Your Profile
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={DistrictOwnerDashboardTabKeys.NOTIFICATION_TAB}>
                  <FontAwesomeIcon icon={faBell} />
                  &nbsp;
                  Notifications
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div className="coachDashboardContentContainer">
            <Tab.Content>
              <Tab.Pane eventKey={DistrictOwnerDashboardTabKeys.YOUR_STAFF}>
                <YourStaffTab />
                <ShareDataModal />
              </Tab.Pane>
              <Tab.Pane
                eventKey={DistrictOwnerDashboardTabKeys.DISTRICT_SUMMARY}
              >
                <DistrictSummaryTab />
              </Tab.Pane>
              <Tab.Pane eventKey={DistrictOwnerDashboardTabKeys.MANAGE_SCHOOLS}>
                <SchoolsManagementTab />
                <UpsertSchoolModal />
              </Tab.Pane>
              {isDataManager || isDistrictOwner && (
                <Tab.Pane
                  eventKey={DistrictOwnerDashboardTabKeys.MANAGE_DISTRICT_DATA}
                >
                  <YourDataAndInterviewsTab tableOnly />

                  <EquityBenchmarksModal />
                  {showClassWideNeedsModal && 
                  <ClassWideNeedsModal /> }
                  <ConfirmRecommendModal />
                  <ConfirmDiagnosisModal />
                  <ManageDataPeriodsModal />
                </Tab.Pane>
              )}
              <Tab.Pane
                eventKey={DistrictOwnerDashboardTabKeys.MANAGE_DATA_ACCESS}
              >
                <DataAccessPage />
              </Tab.Pane>
              {/* <Tab.Pane
                eventKey={DistrictOwnerDashboardTabKeys.UPLOAD_DISTRICT_DATA}
              >
                <UploadDistrictPage />
              </Tab.Pane> */}
              <Tab.Pane
                active={
                  activeTab === DistrictOwnerDashboardTabKeys.ROSTERS_STUDENTS
                }
              >
                <RostersTab
                  activeKey={activeTab as SecondStepTabKeys}
                  id={id}
                />
                <TeacherDetailsModal />
                <InterventionPlanModal studentUniqueId={id} />
              </Tab.Pane>
              {/* <Tab.Pane
                eventKey={DistrictOwnerDashboardTabKeys.CHAT_MODERATION}
              >
                <ChatModerationTab />
              </Tab.Pane>
              {/* <Tab.Pane
                eventKey={DistrictOwnerDashboardTabKeys.ASSESSMENT_LIBRARY}
              >
                <AssessmentLibraryTab />
              </Tab.Pane> */}
              <Tab.Pane
                eventKey={DistrictOwnerDashboardTabKeys.INTERVENTION_LIBRARY}
              >
                <InterventionLibraryTab />
                <CreateNewInterventionModal />
                <InterventionLibraryModal />
              </Tab.Pane>
              <Tab.Pane
                  mountOnEnter
                  unmountOnExit
                  eventKey={DistrictOwnerDashboardTabKeys.TUTORIAL_VIDEOS}
                >
                  <TutorialVideosPage />
                </Tab.Pane>
                <Tab.Pane
                  mountOnEnter
                  unmountOnExit
                  eventKey={DistrictOwnerDashboardTabKeys.RELEASE_NOTES}
                >
                  <ReleaseNotesTab />
                </Tab.Pane>
  
              {/* <Tab.Pane eventKey={DistrictOwnerDashboardTabKeys.MANAGE_CLASSES}>
                <ClassesTab />
              </Tab.Pane> */}
              <Tab.Pane eventKey={DistrictOwnerDashboardTabKeys.YOUR_PROFILE}>
                  <ProfileTab />

                  <ChangePasswordModal />
                  {/* <EditProfileModal /> */}
                </Tab.Pane>
            </Tab.Content>
            <Tab.Pane
              eventKey={DistrictOwnerDashboardTabKeys.NOTIFICATION_TAB}
            >
              <NotificationsTab />
            </Tab.Pane>
          </div>
        </div>
      </Tab.Container>
      <EditProfileModal />
      <ConfirmDialog />
      <UploadStudentsRosterModal />
    </Container>
  );
};

const mapStateToProps = ({ auth, onboarding }: ApplicationState): PropsFromState => {
  return {
    userInfo: auth.userInfo,
    showClassWideNeedsModal: onboarding.modalsState.showClassWideNeedsModal
    
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      push: push,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistrictOwnerDashboard);
