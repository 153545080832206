import React, { Component } from "react";
import LoadingIndicator from "../../onboarding/LoadingIndicator";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ApplicationState } from "../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  changeSelectedTeacher,
  deleteDataSharingTableData,
  getDataSharingTableData,
  openDataSharingEditModal,
  openShareDataModal,
  openTeacherDetailsModal,
  setNewInviteData,
} from "../../../../store/onboarding/actions";
import {
  DataSharingTableData,
  SendInviteRequest,
  TeacherInfo,
} from "../../../../store/onboarding/types";
import DataSharingEditModal from "./modals/DataSharingEditModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ConfirmModal from "../ConfirmModal";
import { toastr } from "react-redux-toastr";
import InviteTeachersModal from "./modals/InviteTeachersModal";

type PropsFromState = {
  tableData: Array<DataSharingTableData>;
  teachers: Array<TeacherInfo>;
  isLoading: {
    deleteDataSharingTableData: boolean;
    sendInviteToTeachers: boolean;
    getDataSharingTableData: boolean;
  };
  errors: {
    deleteDataSharingTableData?: string;
  };
};

type DispatchProps = {
  getDataSharingTableData: () => any;
  deleteDataSharingTableData: (data: DataSharingTableData) => any;
  openShareDataModal: () => any;
  openDataSharingEditModal: () => any;
  setNewInviteData: (data: SendInviteRequest) => any;
  openTeacherDetailsModal: () => any;
  changeSelectedTeacher: (selectedTeacher?: TeacherInfo) => any;
};

type Props = PropsFromState & DispatchProps;

type State = {
  selectedUser?: DataSharingTableData;
  showDeleteModal: boolean;
};

class DataSharing extends Component<Props> {
  defState: Readonly<State> = {
    selectedUser: undefined,
    showDeleteModal: false,
  };

  state: Readonly<State> = this.defState;

  private columnEvents = {
    onClick: (
      e: React.MouseEvent,
      column: any,
      columnIndex: number,
      row: DataSharingTableData
    ) => {
      const selectedData = this.props.tableData.find(
        (data) => data.id === row.id
      );
      if (selectedData && selectedData.relationship_status !== 0) {
        const selectedTeacher = this.props.teachers.find(
          (teacher) => teacher.user.id === selectedData.user.id
        );
        if (selectedTeacher) {
          this.props.changeSelectedTeacher(selectedTeacher);
          this.props.openTeacherDetailsModal();
        }
      }
    },
  };

  columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "date",
      text: "Date",
      hidden: true,
    },
    {
      dataField: "user",
      text: "User",
      sort: true,
      headerStyle: { width: "15%" },
      events: this.columnEvents,
    },
    {
      dataField: "inviteStatus",
      text: "Invite Status",
      sort: true,
      headerStyle: { width: "15%" },
    },
    {
      dataField: "teachers",
      text: "Access To These Teachers' Data",
      sort: true,
      headerStyle: { width: "20%" },
      events: this.columnEvents,
    },
    {
      dataField: "grades",
      text: "Access To These Grades",
      sort: true,
      headerStyle: { width: "20%" },
      events: this.columnEvents,
    },
    {
      dataField: "schools",
      text: "Access To These Schools",
      sort: true,
      headerStyle: { width: "20%" },
      events: this.columnEvents,
    },
    {
      dataField: "actions",
      text: "Actions ",
      sort: false,
      headerStyle: { width: "10%" },
    },
  ];

  defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: "date",
      order: "desc",
    },
  ];

  options = {
    sizePerPageList: [
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.isLoading.sendInviteToTeachers &&
      !this.props.isLoading.sendInviteToTeachers
    ) {
      this.props.getDataSharingTableData();
    }
    if (
      prevProps.isLoading.deleteDataSharingTableData &&
      !this.props.isLoading.deleteDataSharingTableData
    ) {
      if (this.props.errors.deleteDataSharingTableData) {
        toastr.error("Error!", this.props.errors.deleteDataSharingTableData);
      }
    }
  }

  handleEditRow = (selectedUser: DataSharingTableData) => {
    this.setState({ selectedUser });
    this.props.openDataSharingEditModal();
  };

  handleDeleteRow = (row: DataSharingTableData) => {
    this.setState({ selectedUser: row, showDeleteModal: true });
  };

  getTeacher = (row: DataSharingTableData) => {
    if (row.all_data) {
      return "All";
    }

    let resultString: string = "";
    if (row.teachers.length > 0) {
      row.teachers.forEach((teacher) => {
        if (resultString !== "") {
          resultString += ", ";
        }
        resultString += teacher.first_name + " " + teacher.last_name;
      });
    } else if (row.grades && row.grades.length > 0) {
      resultString = "All for selected grades";
    } else {
      resultString = "None";
    }

    return resultString;
  };

  getSchools = (row: DataSharingTableData) => {
    if (row.all_data) {
      return "All";
    }

    let resultString: string = "";
    if (row.schools && row.schools.length > 0) {
      row.schools.forEach((school) => {
        if (resultString !== "") {
          resultString += ", ";
        }
        resultString += school.name;
      });
    } else if (
      (row.grades && row.grades.length > 0) ||
      (row.teachers && row.teachers.length > 0)
    ) {
      resultString = "All for selected grades and/or teachers";
    } else {
      resultString = "None";
    }

    return resultString;
  };

  onHideDeleteModal = () => {
    this.setState({
      groupToChange: undefined,
      showDeleteModal: false,
    });
  };

  onConfirmDeleteModal = () => {
    const { selectedUser } = this.state;
    if (selectedUser) {
      this.props.deleteDataSharingTableData(selectedUser);
    }
  };

  handleInviteFromFile = (data: DataSharingTableData) => () => {
    const invite: SendInviteRequest = {
      first_name: data.user.first_name,
      last_name: data.user.last_name,
      email: "",
      existing_user_id: data.user.id,
      originalUser: data.user,
      teachers: [...data.teachers.map((t) => t.id), data.user.id],
      grades: data.grades,
    };
    this.props.setNewInviteData(invite);
    this.props.openShareDataModal();
  };

  render() {
    const { tableData, isLoading } = this.props;
    const { selectedUser, showDeleteModal } = this.state;

    const getGradeValue = (row: DataSharingTableData): string => {
      if (row.all_data) {
        return "All";
      }

      if (row.grades && row.grades.length > 0) {
        return row.grades.join(", ");
      }

      if (row.teachers && row.teachers.length > 0) {
        return "All for selected teachers";
      }

      return "None";
    };

    return (
      <div>
        <BootstrapTable
          classes="rosterTable"
          wrapperClasses={tableData.length === 0 ? "rosterTableEmpty" : ""}
          keyField="id"
          bordered={false}
          bootstrap4
          data={tableData.map((row) => ({
            id: row.id,
            date: row.updated,
            user: `${row.user.first_name} ${row.user.last_name}`,
            inviteStatus: (
              <>
                {row.relationship_status === 0 ? (
                  <div>{row.relationship_status}</div>
                ) : (
                  <div>
                    <div
                      className="tableRowBtn"
                      onClick={this.handleInviteFromFile(row)}
                    >
                      Click to Invite.
                    </div>
                  </div>
                )}
              </>
            ),
            grades: getGradeValue(row),
            teachers: this.getTeacher(row),
            schools: this.getSchools(row),
            actions: (
              <div className="displayFlex">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="deleteIcon" className="customInfoTooltip">
                      Edit
                    </Tooltip>
                  }
                >
                  <span
                    className="mr-2"
                    onClick={() => this.handleEditRow(row)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="deleteIcon" className="customInfoTooltip">
                      Delete
                    </Tooltip>
                  }
                >
                  <span onClick={() => this.handleDeleteRow(row)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                </OverlayTrigger>
              </div>
            ),
          }))}
          columns={this.columns}
          defaultSorted={this.defaultSorted}
          noDataIndication={<LoadingIndicator />}
          pagination={paginationFactory(this.options)}
        />
        {selectedUser != undefined ? <DataSharingEditModal selectedUser={selectedUser} /> : ''}
        <ConfirmModal
          showModal={showDeleteModal}
          onHide={this.onHideDeleteModal}
          onConfirm={this.onConfirmDeleteModal}
          isLoading={isLoading.deleteDataSharingTableData}
          deleteModal={true}
        />
        <InviteTeachersModal />
      </div>
    );
  }
}

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    tableData: onboarding.dataSharingTableData,
    teachers: onboarding.teachersRoster,
    isLoading: {
      deleteDataSharingTableData:
        onboarding.isLoading.deleteDataSharingTableData,
      sendInviteToTeachers: onboarding.isLoading.sendInviteToTeachers,
      getDataSharingTableData: onboarding.isLoading.getDataSharingTableData,
    },
    errors: {
      deleteDataSharingTableData: onboarding.errors.deleteDataSharingTableData,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getDataSharingTableData: getDataSharingTableData,
      deleteDataSharingTableData: deleteDataSharingTableData,
      openShareDataModal: openShareDataModal,
      openDataSharingEditModal: openDataSharingEditModal,
      setNewInviteData: setNewInviteData,
      openTeacherDetailsModal: openTeacherDetailsModal,
      changeSelectedTeacher: changeSelectedTeacher,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DataSharing);
