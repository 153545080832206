import {
  District,
  StaffRelationship,
  UserAssignmentRole,
  UserInfo,
} from "../auth/types";
import { Grade, SkillFocus, SkillFocusArea, TimePeriod } from "../groups/types";
import {
  AttachedResource,
  GoalTypes,
  Intervention,
  InterventionGroup,
  StudentGoal,
} from "./cases/types";
import { ImplementationCheck } from "./meeting-module/types";

export type OnboardingState = {
  noClassWideNeeds?: boolean;
  isLoading: {
    getDistrictAccessDataSettings?: boolean;
    addDistrictAccessDataSetting?: boolean;
    updateDistrictAccessDataSetting?: boolean;
    deleteDistrictAccessDataSetting?: boolean;
    checkEmailExist: boolean;
    resendInviteToTeachers: boolean;
    resendInviteToUsers: boolean;
    sendInviteToTeachers: boolean;
    getTeachers: boolean;
    addStudents: boolean;
    updateTeacherInfo: boolean;
    assignClassToTeacher: boolean;
    reassignClassToTeacher?: boolean;
    getClassesAssignedToTeacher: boolean;
    assignStudentsToTeacher: Array<number>;
    getStudentsAssignedToTeacher: boolean;
    updateStudentDetails: boolean;
    updateTeacherClass: boolean;
    getSchoolsList: boolean;
    getParentsList: boolean;
    addSchool: boolean;
    updateSchool: boolean;
    deleteSchool: boolean;
    getStudents: boolean;
    uploadStudentDemographics?: boolean;
    mergeTeachers: boolean;
    mergeSchools: boolean;
    getReminders: boolean;
    updateReminder: boolean;
    deleteReminder: boolean;
    scheduleReminder: boolean;
    createAssessment: boolean;
    detectAssessment: boolean;
    uploadEvidenceData: boolean;
    uploadAssessmentData: boolean;
    uploadUndefinedAssessmentData: boolean;
    deleteEvidenceColumnsGroup: boolean;
    getEvidenceInDatePeriod: boolean;
    getEvidenceAssessments: boolean;
    addStudentsToDataPeriod: boolean;
    addAssessmentToDataPeriod: boolean;
    setValueOfDSEtoDataPeriod: boolean;
    updateValueOfDSEtoDataPeriod: boolean;
    setDiagnosticFollowUptoDataPeriod: boolean;
    deleteDiagnosticFollowUptoDataPeriod: boolean;
    detectNonExistingStudentIds: boolean;
    getUpdatedAreaOfNeed: boolean;
    getDataPeriods: boolean;
    addDataPeriod: boolean;
    updateDataPeriod: boolean;
    deleteDataPeriod: boolean;
    getCustomColorCriteria: boolean;
    updateCustomColorCriteria: boolean;
    restoreDefaultBenchmarks: boolean;
    getStaticDataCategoryDomains: boolean;
    getCustomBehaviors?: boolean;
    addCustomBehavior?: boolean;
    addCategoryBehavior: boolean;
    addTargetBehaviorToDataPeriod: boolean;
    changeStudentDSEGrade: boolean;
    addStudentSkills: boolean;
    deleteDataStudentEntry: boolean;
    getDataSharingTableData: boolean;
    updateDataSharingTableData: boolean;
    deleteDataSharingTableData: boolean;
    shareCoachTeacherRelationData: boolean;
    sendManagerKeyRequest: boolean;
    getInterviews: boolean;
    getInterviewsStudents: boolean;
    createInterview: boolean;
    updateInterview?: boolean;
    createInterviewStudent: boolean;
    updateInterviewStudent?: boolean;
    addStudentBehaviorsToDataPeriod: boolean;
    getDefaultProgressMonitoring: boolean;
    addUserToDistrict: boolean;
    addTeachersToCoaches: boolean;
    getDistrictRelationships: boolean;
    getInterventionGroupLogging: boolean;
    addEducatorsToSupervisors: boolean;
    addExistingBehaviorEcgToDataPeriod: boolean;
    addBehaviorScreenerEcgToDataPeriod: boolean;
    addDistrictAssessment: boolean;
    updateDistrictAssessment: boolean;
    assignTeachersToStudent: boolean;
    getClassesLinkedToStudent: boolean;
    getAllClasses: boolean;
    updateClassesLinkedToStudent: boolean;
    getDistrictSummary: boolean;
    getDistrictSummaryReport?: boolean;
    getDistricts?: boolean;
    getTutorialVideos: boolean;
    inviteParent?: boolean;
    addStudentToParent?: boolean;
    sendAssessmentSuggestions?: boolean;
    createDiagnosticInfoColumn?: boolean;
    getCoaches?: boolean;
    changeUserStatusAction?: boolean;
    isLoadingIndicator: boolean;
    getDataPeriodSchools: boolean;
    getRaceInfo: boolean;
    getMasterRaceInfo: boolean;
  };
  errors: {
    getDistrictAccessDataSettings?: string;
    addDistrictAccessDataSetting?: string;
    updateDistrictAccessDataSetting?: string;
    deleteDistrictAccessDataSetting?: string;
    checkEmailExist?: string;
    resendInviteToTeachers?: string;
    resendInviteToUsers?: string;
    sendInviteToTeachers?: string;
    getTeachers?: string;
    addStudents?: string;
    updateTeacherInfo?: string;
    assignClassToTeacher?: string;
    reassignClassToTeacher?: string;
    getClassesAssignedToTeacher?: string;
    assignStudentsToTeacher: Array<{ teacherId: number; message: string }>;
    getStudentsAssignedToTeacher?: string;
    updateStudentDetails?: string;
    updateTeacherClass?: string;
    getSchoolsList?: string;
    getParentsList?: string;
    addSchool?: string;
    updateSchool?: string;
    getStudents?: string;
    uploadStudentDemographics?: string;
    mergeTeachers?: string;
    mergeSchools?: string;
    getReminders?: string;
    updateReminder?: string;
    deleteReminder?: string;
    scheduleReminder?: string;
    createAssessment?: string;
    detectAssessment?: string;
    uploadEvidenceData?: string;
    uploadAssessmentData?: string;
    uploadUndefinedAssessmentData?: string;
    deleteEvidenceColumnsGroup?: string;
    getEvidenceInDatePeriod?: string;
    getEvidenceAssessments?: string;
    addStudentsToDataPeriod?: string;
    updateStudentsInDataPeriod?: string;
    addAssessmentToDataPeriod?: string;
    setValueOfDSEtoDataPeriod?: string;
    updateValueOfDSEtoDataPeriod?: string;
    setDiagnosticFollowUptoDataPeriod?: string;
    deleteDiagnosticFollowUptoDataPeriod?: string;
    detectNonExistingStudentIds?: string;
    getUpdatedAreaOfNeed?:string;
    getDataPeriods?: string;
    addDataPeriod?: string;
    updateDataPeriod?: string;
    deleteDataPeriod?: string;
    getCustomColorCriteria?: string;
    updateCustomColorCriteria?: string;
    restoreDefaultBenchmarks?: string;
    getStaticDataCategoryDomains?: string;
    addCategoryBehavior?: string;
    addTargetBehaviorToDataPeriod?: string;
    changeStudentDSEGrade?: string;
    addStudentSkills?: string;
    deleteDataStudentEntry?: string;
    getDataSharingTableData?: string;
    updateDataSharingTableData?: string;
    deleteDataSharingTableData?: string;
    shareCoachTeacherRelationData?: string;
    sendManagerKeyRequest?: string;
    getInterviews?: string;
    getInterviewsStudents?: string;
    createInterview?: string;
    createInterviewStudent?: string;
    addStudentBehaviorsToDataPeriod?: string;
    getDefaultProgressMonitoring?: string;
    addUserToDistrict?: string;
    getDistrictRelationships?: string;
    getInterventionGroupLogging?: string;
    addTeachersToCoaches?: string;
    addEducatorsToSupervisors?: string;
    addExistingBehaviorEcgToDataPeriod?: string;
    addBehaviorScreenerEcgToDataPeriod?: string;
    addDistrictAssessment?: string;
    updateDistrictAssessment?: string;
    assignTeachersToStudent?: string;
    getClassesLinkedToStudent?: string;
    getAllClasses?: string;
    updateClassesLinkedToStudent?: string;
    getDistrictSummary?: string;
    getDistrictSummaryReport?: string;
    getDistricts?: string;
    getTutorialVideos?: string;
    getCoaches?: string;
    changeUserStatusAction?: string;
    getDataPeriodSchools?: string;
    getRaceInfo?: string;
    getMasterRaceInfo?: string;
  };
  loadableFile?: {
    originalFileName?: string;
    columnsNames: Array<string>;
    rawData: Array<any>;
    generatedStudentIdColumnName?: string;
    uploadCSVArray?:any
  };
  modalsState: {
    showStudentEditModal?: boolean;
    showInterventionStatsModal?: boolean;
    showUploadStudentDemographicModal?: boolean;
    uploadTeachersRosterModal: boolean;
    districtAccessDataSettingUpsertModal?: boolean;
    districtDataPeriodUpsertModal?: boolean;
    teachersIndividuallyInviteModal: boolean;
    teachersIndividuallyInviteModalCallback?: Function;
    uploadStudentsRosterModal: boolean;
    schoolModal: boolean;
    mergeSchoolsModal: boolean;
    studentsIndividuallyInviteModal: boolean;
    teacherDetailModal: boolean;
    assignEducatorsToEmployee: boolean;
    uploadStudentsToTeachersModal: boolean;
    editStudentForm: boolean;
    createClassModal: boolean;
    showFailedInvitationsAlert: boolean;
    scheduleReminderModal: boolean;
    editReminderModal: boolean;
    mergeTeachersModal: boolean;
    saveKeyModal: boolean;
    shareDataModal: boolean;
    coachTeacherRelationModal: boolean;
    loadKeyModal: boolean;
    viewBenchmarksModal: boolean;
    editMeasureColorCriteriaModal: boolean;
    pickDataSourceModal: boolean;
    confirmRecommendModal: boolean;
    confirmSecondaryGoalCreateModal: boolean;
    hasBehavior: boolean;
    hasAcademic: boolean;
    uploadStudentDataModal: boolean;
    enterDataManuallyModal: boolean;
    enterDataManuallyType?: GoalTypes;
    searchForYourAssessmentModal: boolean;
    createAssessmentModal: boolean;
    conductInterviewModal: boolean;
    dataSharingEditModal: boolean;
    generatePDFModal: boolean;
    inviteTeachersModal: boolean;
    upsertSchoolModal: boolean;
    upsertSchoolModalConfig?: {
      districtId?: number;
    };
    manageDataPeriodsModal: boolean;
    inviteParentModal: boolean;
    evidenceConfirmDeletionModal?: boolean;
    showClassWideNeedsModal?: boolean;
    showPreClassWideNeedsModal?: boolean;
    showEquityBenchmarksModal?: boolean;
    isNewProgressMonitoring?: boolean;
    isRedirectToSetGoal?: boolean;
  };
  newTeacherForCreatable?: BasicUserInfo;
  selectedTeacher?: TeacherInfo;
  selectedClass?: TeacherClass;
  selectedSchool?: School;
  selectedParent?: UserInfo;
  selectedReminder?: Reminder;
  selectedEvidenceColumnGroup?: EvidenceColumnGroup;
  timeElipse?: boolean;
  schools: Array<School>;
  parents: Array<UserInfo>;
  teachersRoster: Array<TeacherInfo>;
  teachersRosterWithDummyUser: Array<TeacherInfo>;
  studentsByInterventionGroupRoster: Array<any>;
  coachesRoster: Array<CoachInfo>;
  coachesRosterWithDummyUser: Array<CoachInfo>;
  studentsRoster: Array<StudentInfo>;
  teachersClasses: Array<TeacherClass>;
  teachersStudents: Array<Student>;
  districtRelationships: Array<StaffRelationship>;
  interventionGroupLogging: Array<any>;
  reminders: Array<Reminder>;
  invites: InvitesResponse;
  studentsFilter: string;
  teachersFilter: string;
  messageParts: Array<string>
  studentsDisplaySettings: StudentsDisplaySettings;
  assessments: Array<Assessment>;
  evidencePeriodData?: EvidencePeriodData;
  evidenceAssessments: Array<EvidenceAssessment>;
  nonExistingStudentIds: Array<string>;
  teacherIdFilter?: number[];
  dataPeriods: Array<DataPeriod>;
  currentDataPeriod?: DataPeriod;
  selectedStudentEntry?: StudentEntry;
  selectedStudent?: Student;
  selectedEducator?: UserInfo;
  allClasses: Array<TeacherStudentClass>;
  classesLinkedToStudent: Array<TeacherStudentClass>;
  measurementCustomColorCriteria: Array<ColorCriteria>;
  dataSharingTableData: Array<DataSharingTableData>;
  newInviteData?: SendInviteRequest;
  staticDataCategoryDomains: Array<CategoryBehavior>;
  customBehaviors: Array<CategoryBehavior>;
  selectedInterview?: Interview;
  // selectedInterviewStudents?: Array<InterviewStudent>;
  interviews: Array<Interview>;
  interviewsStudents: Array<InterviewStudent>;
  defaultProgressMonitoring?: Measurement;
  district_summary?: DistrictSummary;
  districtSummaryOptions: {
    collapsedAreas: {
      [key: string]: boolean;
    };
    displayCharts: boolean;
  };
  districts: Array<District>;
  customBehaviorName?: string;
  intGrpAccessObj?: any;
  tutorialVideos: TutorialVideo[];
  collapsedIntGroupAssignmentsIds: number[];
  collapsedIntGroupInnerIds: any[];

  districtAccessDataSettings: DistrictAccessDataSetting[];
  selectedDistrictAccessDataSetting?: Partial<
    DistrictAccessDataSetting<number, UserInfo, number>
  >;

  selectedDistrictDataPeriod?: DataPeriod;

  showDisabledUsers?: boolean;

  selectedEvidenceColumnGroupId?: number;
  uploadedAssessment: any;
  isUploadedAssessment: boolean;
  teacherClassStudent: any;
  selectedUsers: any[];
  callFrom?: string;
  isUrlBlocked: boolean;
  evidenceAssessment?: EvidenceAssessment,
  finishedInterventionGroup?: InterventionGroup,
  dataPeriodSchools: Array<School>;
  raceInfo: RaceInfo;
  masterRaceInfo: Partial<RaceInfo>;
  existingEmails: EmailCheckResponse;
  isStudentRosterUploaded?: boolean;
};

export type DistrictSummary = {
  assignments: Array<AssignmentSummary>;
  goals: Array<GoalSummary>;
  feedback_sessions: Array<CoachTeacherTotalSummary>;
  interventions: Array<InterventionSummary>;
  goal_progress: Array<CoachTeacherTotalSummary>;
  decisions: Array<Decision>;
  goal_attainment: Array<GoalAttainment>;
  intervention_fidelity: Array<InterventionFidelity>;
  fidelity_by_teacher: FidelityByTeacher[];
  fidelity_by_intervention: FidelityByIntervention[];
  group_progress: GroupProgress[];
  group_info: GroupInfo[];
  academic_stats: {
    students: number;
    teachers: number;
    coaches: number;
    goals?: number;
    unique_interventions_count?: number;
  };
  all_logged_interventions: ImplementationCheck[];
};

export type GroupInfo = {
  coach: UserInfo;
  planned_count: number;
  implemented_count: number;
  with_alerts_count: number;
};

export type AssignmentSummary = {
  coach: UserInfo;
  teachers: Array<AssignmentTeacherSummary>;
};

export type AssignmentTeacherSummary = {
  teacher: UserInfo;
  student_count: number;
};

export type GoalSummary = {
  goal: StudentGoal;
  coach: UserInfo;
  teacher: UserInfo;
};

export type CoachTeacherTotalSummary = {
  coach: UserInfo;
  total: number;
  teachers: Array<FeedbackSessionTeacherSummary>;
  unique_goal_count: number;
};

export type FeedbackSessionTeacherSummary = {
  teacher: UserInfo;
  total: number;
};

export type InterventionSummary = {
  coach: UserInfo;
  interventions: Array<InterventionTeacherSummary>;
};

export type InterventionTeacherSummary = {
  teachers: Array<UserInfo>;
  intervention: Intervention;
};

export type Decision = {
  title: string;
  count: number;
};

export type GoalAttainment = {
  coach: UserInfo;
  avg_attainment: number;
};

export type InterventionFidelity = {
  coach: UserInfo;
  avg_fidelity: number;
};

export type FidelityByTeacher = {
  coach: UserInfo;
  teacher: UserInfo;
  fidelity: number;
};

export type FidelityByIntervention = {
  coach: UserInfo;
  intervention: Intervention;
  fidelity: number;
};

export type GroupProgress = {
  coach: UserInfo;
  intervention_group: InterventionGroup;
  progress_percentage: number;
};

export enum StudentsDisplaySettings {
  DISPLAY_ONLY_MY_STUDENTS = 1,
  DISPLAY_ALL_STUDENTS = 2,
}

export type StudentInfo = Student & {
  teachers: Array<UserInfo>;
  classes?: Array<StudentClass>;
};

export type StudentClass = {
  id: number;
  name: string;
};

export type TeacherStudentClass = {
  teacher: UserInfo;
  class: StudentClass;
};

export type TutorialVideo = {
  id: number;
  title: string;
  description: string;
  tags: Array<string>;
  video_file: string;
  external_video_url: string;
  preview: string;
  additional_details: {
    time_staps: Array<TutorialTimeStamp>
  };
  is_active: boolean;
  thumbnail: string;
  created: Date; 
  updated: Date;
};

export type TutorialTimeStamp = {
  title: string;
  value: string;
};

export enum OnboardingActionTypes {
  UPLOAD_CSV_FILE = "@@onboarding/UPLOAD_CSV_FILE",
  UPLOAD_MULTI_CSV_FILE = "@@onboarding/UPLOAD_MULTI_CSV_FILE",
  CLEAR_STORED_CSV_FILE = "@@onboarding/CLEAR_STORED_CSV_FILE",

  UPDATE_CSV_FILE_RAW_DATA = "@@onboarding/UPDATE_CSV_FILE_RAW_DATA",

  OPEN_UPLOAD_TEACHERS_ROSTER_MODAL = "@@onboarding/OPEN_UPLOAD_TEACHERS_ROSTER_MODAL",
  HIDE_UPLOAD_TEACHERS_ROSTER_MODAL = "@@onboarding/HIDE_UPLOAD_TEACHERS_ROSTER_MODAL",

  OPEN_TEACHERS_INVITE_INDIVIDUALLY_MODAL = "@@onboarding/OPEN_TEACHERS_INVITE_INDIVIDUALLY_MODAL",
  HIDE_TEACHERS_INVITE_INDIVIDUALLY_MODAL = "@@onboarding/HIDE_TEACHERS_INVITE_INDIVIDUALLY_MODAL",

  OPEN_UPLOAD_STUDENTS_ROSTER_MODAL = "@@onboarding/OPEN_UPLOAD_STUDENTS_ROSTER_MODAL",
  HIDE_UPLOAD_STUDENTS_ROSTER_MODAL = "@@onboarding/HIDE_UPLOAD_STUDENTS_ROSTER_MODAL",

  OPEN_SCHOOL_MODAL = "@@onboarding/OPEN_ADD_SCHOOL_MODAL",
  HIDE_SCHOOL_MODAL = "@@onboarding/HIDE_ADD_SCHOOL_MODAL",

  OPEN_MERGE_SCHOOLS_MODAL = "@@onboarding/OPEN_MERGE_SCHOOLS_MODAL",
  HIDE_MERGE_SCHOOLS_MODAL = "@@onboarding/HIDE_MERGE_SCHOOLS_MODAL",

  OPEN_STUDENTS_INVITE_INDIVIDUALLY_MODAL = "@@onboarding/OPEN_STUDENTS_INVITE_INDIVIDUALLY_MODAL",
  HIDE_STUDENTS_INVITE_INDIVIDUALLY_MODAL = "@@onboarding/HIDE_STUDENTS_INVITE_INDIVIDUALLY_MODAL",

  OPEN_TEACHER_DETAIL_MODAL = "@@onboarding/OPEN_TEACHER_DETAIL_MODAL",
  HIDE_TEACHER_DETAIL_MODAL = "@@onboarding/HIDE_TEACHER_DETAIL_MODAL",

  OPEN_ASSIGN_EDUCATORS_TO_EMPLOYEE_MODAL = "@@onboarding/OPEN_ASSIGN_EDUCATORS_TO_COACH_MODAL",
  HIDE_ASSIGN_EDUCATORS_TO_EMPLOYEE_MODAL = "@@onboarding/HIDE_ASSIGN_EDUCATORS_TO_COACH_MODAL",

  OPEN_UPLOAD_STUDENTS_TO_TEACHER_MODAL = "@@onboarding/OPEN_UPLOAD_STUDENTS_TO_TEACHER_MODAL",
  HIDE_UPLOAD_STUDENTS_TO_TEACHER_MODAL = "@@onboarding/HIDE_UPLOAD_STUDENTS_TO_TEACHER_MODAL",

  OPEN_SCHEDULE_REMINDER_MODAL = "@@onboarding/OPEN_SCHEDULE_REMINDER_MODAL",
  HIDE_SCHEDULE_REMINDER_MODAL = "@@onboarding/HIDE_SCHEDULE_REMINDER_MODAL",

  OPEN_EDIT_REMINDER_MODAL = "@@onboarding/OPEN_EDIT_REMINDER_MODAL",
  HIDE_EDIT_REMINDER_MODAL = "@@onboarding/HIDE_EDIT_REMINDER_MODAL",

  OPEN_MERGE_TEACHERS_MODAL = "@@onboarding/OPEN_MERGE_TEACHERS_MODAL",
  HIDE_MERGE_TEACHERS_MODAL = "@@onboarding/HIDE_MERGE_TEACHERS_MODAL",

  OPEN_EDIT_STUDENT_FORM = "@@onboarding/OPEN_EDIT_STUDENT_FORM",
  HIDE_EDIT_STUDENT_FORM = "@@onboarding/HIDE_EDIT_STUDENT_FORM",

  OPEN_CREATE_CLASS_MODAL = "@@onboarding/OPEN_CREATE_CLASS_MODAL",
  HIDE_CREATE_CLASS_MODAL = "@@onboarding/HIDE_CREATE_CLASS_MODAL",

  HIDE_FAILED_INVITATIONS_ALERT = "@@onboarding/HIDE_FAILED_INVITATIONS_ALERT",
  HIDE_CHECK_EMAIL_EXIST_ALERT = "@@onboarding/HIDE_CHECK_EMAIL_EXIST_ALERT",

  GET_REMINDERS_START = "@@onboarding/GET_REMINDERS_START",
  GET_REMINDERS_SUCCESS = "@@onboarding/GET_REMINDERS_SUCCESS",
  GET_REMINDERS_FAILURE = "@@onboarding/GET_REMINDERS_FAILURE",

  UPDATE_REMINDER_START = "@@onboarding/UPDATE_REMINDER_START",
  UPDATE_REMINDER_SUCCESS = "@@onboarding/UPDATE_REMINDER_SUCCESS",
  UPDATE_REMINDER_FAILURE = "@@onboarding/UPDATE_REMINDER_FAILURE",

  DELETE_REMINDER_START = "@@onboarding/DELETE_REMINDER_START",
  DELETE_REMINDER_SUCCESS = "@@onboarding/DELETE_REMINDER_SUCCESS",
  DELETE_REMINDER_FAILURE = "@@onboarding/DELETE_REMINDER_FAILURE",

  SCHEDULE_REMINDER_START = "@@onboarding/SCHEDULE_REMINDER_START",
  SCHEDULE_REMINDER_SUCCESS = "@@onboarding/SCHEDULE_REMINDER_SUCCESS",
  SCHEDULE_REMINDER_FAILURE = "@@onboarding/SCHEDULE_REMINDER_FAILURE",

  MERGE_TEACHERS_START = "@@onboarding/MERGE_TEACHERS_START",
  MERGE_TEACHERS_SUCCESS = "@@onboarding/MERGE_TEACHERS_SUCCESS",
  MERGE_TEACHERS_FAILURE = "@@onboarding/MERGE_TEACHERS_FAILURE",

  MERGE_SCHOOLS_START = "@@onboarding/MERGE_SCHOOLS_START",
  MERGE_SCHOOLS_SUCCESS = "@@onboarding/MERGE_SCHOOLS_SUCCESS",
  MERGE_SCHOOLS_FAILURE = "@@onboarding/MERGE_SCHOOLS_FAILURE",

  RESEND_INVITE_TO_USERS_START = "@@onboarding/RESEND_INVITE_TO_USERS_START",
  ADD_USER_TO_DISTRICT_REQUEST = "@@onboarding/ADD_USER_TO_DISTRICT_REQUEST",
  ADD_USER_TO_DISTRICT_SUCCESS = "@@onboarding/ADD_USER_TO_DISTRICT_SUCCESS",
  ADD_USER_TO_DISTRICT_FAILURE = "@@onboarding/ADD_USER_TO_DISTRICT_FAILURE",

  CHECK_EMAIL_EXIST_REQUEST = "@@onboarding/CHECK_EMAIL_EXIST_REQUEST",
  CHECK_EMAIL_EXIST_SUCCESS = "@@onboarding/CHECK_EMAIL_EXIST_SUCCESS",
  CHECK_EMAIL_EXIST_FAILURE = "@@onboarding/CHECK_EMAIL_EXIST_FAILURE",

  RESEND_INVITE_TO_TEACHERS_START = "@@onboarding/RESEND_INVITE_TO_TEACHERS_START",
  SEND_INVITE_TO_TEACHERS_START = "@@onboarding/SEND_INVITE_TO_TEACHERS_START",
  SEND_INVITE_TO_TEACHERS_SUCCESS = "@@onboarding/SEND_INVITE_TO_TEACHERS_SUCCESS",
  SEND_INVITE_TO_TEACHERS_FAILURE = "@@onboarding/SEND_INVITE_TO_TEACHERS_FAILURE",

  GET_TEACHERS_START = "@@onboarding/GET_TEACHERS_START",
  GET_TEACHERS_SUCCESS = "@@onboarding/GET_TEACHERS_SUCCESS",
  GET_TEACHERS_FAILURE = "@@onboarding/GET_TEACHERS_FAILURE",

  GET_STUDENTS_BY_INTERVENTION_GROUP_START = "@@onboarding/GET_STUDENTS_BY_INTERVENTION_GROUP_START",
  GET_STUDENTS_BY_INTERVENTION_GROUP_SUCCESS = "@@onboarding/GET_STUDENTS_BY_INTERVENTION_GROUP_SUCCESS",
  GET_STUDENTS_BY_INTERVENTION_GROUP_FAILURE = "@@onboarding/GET_STUDENTS_BY_INTERVENTION_GROUP_FAILURE",

  GET_STUDENTS_START = "@@onboarding/GET_STUDENTS_START",
  GET_STUDENTS_SUCCESS = "@@onboarding/GET_STUDENTS_SUCCESS",
  GET_STUDENTS_FAILURE = "@@onboarding/GET_STUDENTS_FAILURE",

  ADD_STUDENTS_START = "@@onboarding/ADD_STUDENTS_START",
  ADD_STUDENTS_SUCCESS = "@@onboarding/ADD_STUDENTS_SUCCESS",
  ADD_STUDENTS_FAILURE = "@@onboarding/ADD_STUDENTS_FAILURE",

  UPDATE_TEACHER_INFO_START = "@@onboarding/UPDATE_TEACHER_INFO_START",
  UPDATE_TEACHER_INFO_SUCCESS = "@@onboarding/UPDATE_TEACHER_INFO_SUCCESS",
  UPDATE_TEACHER_INFO_FAILURE = "@@onboarding/UPDATE_TEACHER_INFO_FAILURE",

  ASSIGN_CLASS_TO_TEACHER_START = "@@onboarding/ASSIGN_CLASS_TO_TEACHER_START",
  ASSIGN_CLASS_TO_TEACHER_SUCCESS = "@@onboarding/ASSIGN_CLASS_TO_TEACHER_SUCCESS",
  ASSIGN_CLASS_TO_TEACHER_FAILURE = "@@onboarding/ASSIGN_CLASS_TO_TEACHER_FAILURE",

  UPDATE_TEACHER_CLASS_START = "@@onboarding/UPDATE_TEACHER_CLASS_START",
  UPDATE_TEACHER_CLASS_SUCCESS = "@@onboarding/UPDATE_TEACHER_CLASS_SUCCESS",
  UPDATE_TEACHER_CLASS_FAILURE = "@@onboarding/UPDATE_TEACHER_CLASS_FAILURE",

  GET_CLASSES_ASSIGNED_TO_TEACHER_START = "@@onboarding/GET_CLASSES_ASSIGNED_TO_TEACHER_START",
  GET_CLASSES_ASSIGNED_TO_TEACHER_SUCCESS = "@@onboarding/GET_CLASSES_ASSIGNED_TO_TEACHER_SUCCESS",
  GET_CLASSES_ASSIGNED_TO_TEACHER_FAILURE = "@@onboarding/GET_CLASSES_ASSIGNED_TO_TEACHER_FAILURE",

  CLEAR_CLASSES_ASSIGNED_TO_TEACHER = "@@onboarding/CLEAR_CLASSES_ASSIGNED_TO_TEACHER",

  ASSIGN_STUDENTS_TO_TEACHER_START = "@@onboarding/ASSIGN_STUDENTS_TO_TEACHER_START",
  ASSIGN_STUDENTS_TO_TEACHER_SUCCESS = "@@onboarding/ASSIGN_STUDENTS_TO_TEACHER_SUCCESS",
  ASSIGN_STUDENTS_TO_TEACHER_FAILURE = "@@onboarding/ASSIGN_STUDENTS_TO_TEACHER_FAILURE",

  UPDATE_STUDENT_DETAILS_START = "@@onboarding/UPDATE_STUDENT_DETAILS_START",
  UPDATE_STUDENT_DETAILS_SUCCESS = "@@onboarding/UPDATE_STUDENT_DETAILS_SUCCESS",
  UPDATE_STUDENT_DETAILS_FAILURE = "@@onboarding/UPDATE_STUDENT_DETAILS_FAILURE",

  GET_STUDENTS_ASSIGNED_TO_TEACHER_START = "@@onboarding/GET_STUDENTS_ASSIGNED_TO_TEACHER_START",
  GET_STUDENTS_ASSIGNED_TO_TEACHER_SUCCESS = "@@onboarding/GET_STUDENTS_ASSIGNED_TO_TEACHER_SUCCESS",
  GET_STUDENTS_ASSIGNED_TO_TEACHER_FAILURE = "@@onboarding/GET_STUDENTS_ASSIGNED_TO_TEACHER_FAILURE",

  GET_SCHOOLS_LIST_START = "@@onboarding/GET_SCHOOLS_LIST_START",
  GET_SCHOOLS_LIST_SUCCESS = "@@onboarding/GET_SCHOOLS_LIST_SUCCESS",
  GET_SCHOOLS_LIST_FAILURE = "@@onboarding/GET_SCHOOLS_LIST_FAILURE",

  GET_PARENTS_LIST_START = "@@onboarding/GET_PARENTS_LIST_START",
  GET_PARENTS_LIST_SUCCESS = "@@onboarding/GET_PARENTS_LIST_SUCCESS",
  GET_PARENTS_LIST_FAILURE = "@@onboarding/GET_PARENTS_LIST_FAILURE",

  ADD_SCHOOL_START = "@@onboarding/ADD_SCHOOL_START",
  ADD_SCHOOL_SUCCESS = "@@onboarding/ADD_SCHOOL_SUCCESS",
  ADD_SCHOOL_FAILURE = "@@onboarding/ADD_SCHOOL_FAILURE",

  UPDATE_SCHOOL_START = "@@onboarding/UPDATE_SCHOOL_START",
  UPDATE_SCHOOL_SUCCESS = "@@onboarding/UPDATE_SCHOOL_SUCCESS",
  UPDATE_SCHOOL_FAILURE = "@@onboarding/UPDATE_SCHOOL_FAILURE",

  DELETE_SCHOOL_START = "@@onboarding/DELETE_SCHOOL_START",
  DELETE_SCHOOL_SUCCESS = "@@onboarding/DELETE_SCHOOL_SUCCESS",
  DELETE_SCHOOL_FAILURE = "@@onboarding/DELETE_SCHOOL_FAILURE",

  SELECTED_TEACHER_CHANGED = "@@onboarding/SELECTED_TEACHER_CHANGED",
  SELECTED_STUDENT_CHANGED = "@@onboarding/SELECTED_STUDENT_CHANGED",
  SELECTED_CLASS_CHANGED = "@@onboarding/SELECTED_CLASS_CHANGED",
  SELECTED_REMINDER_CHANGED = "@@onboarding/SELECTED_REMINDER_CHANGED",

  CHANGE_STUDENTS_FILTER = "@@onboarding/CHANGE_STUDENTS_FILTER",
  CHANGE_TEACHER_SEARCH_FILTER = "@@onboarding/CHANGE_TEACHER_SEARCH_FILTER",
  CHANGE_STUDENTS_DISPLAY_SETTINGS = "@@onboarding/CHANGE_STUDENTS_DISPLAY_SETTINGS",
  SEND_DATA_FILTER_MESSAGE = "@@onboarding/SEND_DATA_FILTER_MESSAGE",

  UPDATE_SELECTED_USER_DATA = "@@onboarding/UPDATE_SELECTED_USER_DATA",

  UPDATE_RAW_DATA_OF_CSV_FILE = "@@uploadStudentData/UPDATE_RAW_DATA_OF_CSV_FILE",

  OPEN_SAVE_KEY_MODAL = "@@uploadStudentData/OPEN_SAVE_KEY_MODAL",
  HIDE_SAVE_KEY_MODAL = "@@uploadStudentData/HIDE_SAVE_KEY_MODAL",

  OPEN_LOAD_KEY_MODAL = "@@uploadStudentData/OPEN_LOAD_KEY_MODAL",
  HIDE_LOAD_KEY_MODAL = "@@uploadStudentData/HIDE_LOAD_KEY_MODAL",

  OPEN_VIEW_BENCHMARKS_MODAL = "@@uploadStudentData/OPEN_VIEW_BENCHMARKS_MODAL",
  HIDE_VIEW_BENCHMARKS_MODAL = "@@uploadStudentData/HIDE_VIEW_BENCHMARKS_MODAL",

  OPEN_EDIT_MEASURE_COLOR_CRITERIA_MODAL = "@@uploadStudentData/OPEN_EDIT_MEASURE_COLOR_CRITERIA_MODAL",
  HIDE_EDIT_MEASURE_COLOR_CRITERIA_MODAL = "@@uploadStudentData/HIDE_EDIT_MEASURE_COLOR_CRITERIA_MODAL",

  OPEN_PICK_DATA_SOURCE_MODAL = "@@uploadStudentData/OPEN_PICK_DATA_SOURCE_MODAL",
  HIDE_PICK_DATA_SOURCE_MODAL = "@@uploadStudentData/HIDE_PICK_DATA_SOURCE_MODAL",

  OPEN_CONFIRM_RECOMMEND_MODAL = "@@uploadStudentData/OPEN_CONFIRM_RECOMMEND_MODAL",
  HIDE_CONFIRM_RECOMMEND_MODAL = "@@uploadStudentData/HIDE_CONFIRM_RECOMMEND_MODAL",

  OPEN_CONFIRM_SECONDARY_GOAL_CREATE_MODAL = "@@uploadStudentData/OPEN_CONFIRM_SECONDARY_GOAL_CREATE_MODAL",
  HIDE_CONFIRM_SECONDARY_GOAL_CREATE_MODAL = "@@uploadStudentData/HIDE_CONFIRM_SECONDARY_GOAL_CREATE_MODAL",

  OPEN_UPLOAD_STUDENT_DATA_MODAL = "@@uploadStudentData/OPEN_UPLOAD_STUDENT_DATA_MODAL",
  HIDE_UPLOAD_STUDENT_DATA_MODAL = "@@uploadStudentData/HIDE_UPLOAD_STUDENT_DATA_MODAL",

  OPEN_ENTER_DATA_MANUALLY_MODAL = "@@uploadStudentData/OPEN_ENTER_DATA_MANUALLY_MODAL",
  HIDE_ENTER_DATA_MANUALLY_MODAL = "@@uploadStudentData/HIDE_ENTER_DATA_MANUALLY_MODAL",

  OPEN_SEARCH_FOR_YOUR_ASSESSMENT_MODAL = "@@uploadStudentData/OPEN_SEARCH_FOR_YOUR_ASSESSMENT_MODAL",
  HIDE_SEARCH_FOR_YOUR_ASSESSMENT_MODAL = "@@uploadStudentData/HIDE_SEARCH_FOR_YOUR_ASSESSMENT_MODAL",

  OPEN_ADD_STUDENTS_TO_TABLE_MODAL = "@@uploadStudentData/OPEN_ADD_STUDENTS_TO_TABLE_MODAL",
  HIDE_ADD_STUDENTS_TO_TABLE_MODAL = "@@uploadStudentData/HIDE_ADD_STUDENTS_TO_TABLE_MODAL",

  OPEN_ADD_ASSESSMENT_MODAL = "@@uploadStudentData/OPEN_ADD_ASSESSMENT_MODAL",
  HIDE_ADD_ASSESSMENT_MODAL = "@@uploadStudentData/HIDE_ADD_ASSESSMENT_MODAL",
  HIDE_ADD_PROGRESS_MONITOR_MODAL = "@@uploadStudentData/HIDE_ADD_PROGRESS_MONITOR_MODAL",
  RESET_REDIRECT_TO_SET_GOAL = "@@uploadStudentData/RESET_REDIRECT_TO_SET_GOAL",
  
  OPEN_CONDUCT_INTERVIEW_MODAL = "@@uploadStudentData/evidence/OPEN_CONDUCT_INTERVIEW_MODAL",
  HIDE_CONDUCT_INTERVIEW_MODAL = "@@uploadStudentData/evidence/HIDE_CONDUCT_INTERVIEW_MODAL",

  DETECT_ASSESSMENT_START = "@@uploadStudentData/DETECT_ASSESSMENT_START",
  DETECT_ASSESSMENT_SUCCESS = "@@uploadStudentData/DETECT_ASSESSMENT_SUCCESS",
  DETECT_ASSESSMENT_FAILURE = "@@uploadStudentData/DETECT_ASSESSMENT_FAILURE",

  UPLOAD_EVIDENCE_DATA_START = "@@uploadStudentData/UPLOAD_EVIDENCE_DATA_START",
  UPLOAD_EVIDENCE_DATA_SUCCESS = "@@uploadStudentData/UPLOAD_EVIDENCE_DATA_SUCCESS",
  UPLOAD_EVIDENCE_DATA_FAILURE = "@@uploadStudentData/UPLOAD_EVIDENCE_DATA_FAILURE",

  SEND_MANAGER_KEY_REQUEST_START = "@@uploadStudentData/SEND_MANAGER_KEY_REQUEST_START",
  SEND_MANAGER_KEY_REQUEST_SUCCESS = "@@uploadStudentData/SEND_MANAGER_KEY_REQUEST_SUCCESS",
  SEND_MANAGER_KEY_REQUEST_FAILURE = "@@uploadStudentData/SEND_MANAGER_KEY_REQUEST_FAILURE",

  UPLOAD_ASSESSMENT_DATA_START = "@@uploadStudentData/UPLOAD_ASSESSMENT_DATA_START",
  UPLOAD_ASSESSMENT_DATA_SUCCESS = "@@uploadStudentData/UPLOAD_ASSESSMENT_DATA_SUCCESS",
  UPLOAD_ASSESSMENT_DATA_FAILURE = "@@uploadStudentData/UPLOAD_ASSESSMENT_DATA_FAILURE",
  RESET_IS_NEW_UPLOAD_ASSESSMENT = "@@uploadStudentData/RESET_IS_NEW_UPLOAD_ASSESSMENT",

  UPLOAD_UNDEFINED_ASSESSMENT_DATA_START = "@@uploadStudentData/UPLOAD_UNDEFINED_ASSESSMENT_DATA_START",
  UPLOAD_UNDEFINED_ASSESSMENT_SET_STUDENT_ID = "@@uploadStudentData/UPLOAD_UNDEFINED_ASSESSMENT_SET_STUDENT_ID",
  UPLOAD_UNDEFINED_ASSESSMENT_DATA_SUCCESS = "@@uploadStudentData/UPLOAD_UNDEFINED_ASSESSMENT_DATA_SUCCESS",
  UPLOAD_UNDEFINED_ASSESSMENT_DATA_FAILURE = "@@uploadStudentData/UPLOAD_UNDEFINED_ASSESSMENT_DATA_FAILURE",

  GET_EVIDENCE_ASSESSMENTS_START = "@@uploadStudentData/GET_EVIDENCE_ASSESSMENTS_START",
  GET_EVIDENCE_ASSESSMENTS_SUCCESS = "@@uploadStudentData/GET_EVIDENCE_ASSESSMENTS_SUCCESS",
  GET_EVIDENCE_ASSESSMENTS_FAILURE = "@@uploadStudentData/GET_EVIDENCE_ASSESSMENTS_FAILURE",

  DELETE_EVIDENCE_COLUMNS_GROUP_START = "@@uploadStudentData/DELETE_EVIDENCE_COLUMNS_GROUP_START",
  DELETE_EVIDENCE_COLUMNS_GROUP_SUCCESS = "@@uploadStudentData/DELETE_EVIDENCE_COLUMNS_GROUP_SUCCESS",
  DELETE_EVIDENCE_COLUMNS_GROUP_FAILURE = "@@uploadStudentData/DELETE_EVIDENCE_COLUMNS_GROUP_FAILURE",

  GET_EVIDENCE_IN_DATA_PERIOD_START = "@@uploadStudentData/GET_EVIDENCE_IN_DATA_PERIOD_START",
  GET_EVIDENCE_IN_DATA_PERIOD_SUCCESS = "@@uploadStudentData/GET_EVIDENCE_IN_DATA_PERIOD_SUCCESS",
  GET_EVIDENCE_IN_DATA_PERIOD_FAILURE = "@@uploadStudentData/GET_EVIDENCE_IN_DATA_PERIOD_FAILURE",

  ADD_STUDENTS_TO_DATA_PERIOD_START = "@@uploadStudentData/ADD_STUDENTS_TO_DATA_PERIOD_START",
  ADD_STUDENTS_TO_DATA_PERIOD_SUCCESS = "@@uploadStudentData/ADD_STUDENTS_TO_DATA_PERIOD_SUCCESS",
  ADD_STUDENTS_TO_DATA_PERIOD_FAILURE = "@@uploadStudentData/ADD_STUDENTS_TO_DATA_PERIOD_FAILURE",

  ASSIGN_TEACHERS_TO_STUDENT_START = "@@uploadStudentData/ASSIGN_TEACHERS_TO_STUDENT_START",
  ASSIGN_TEACHERS_TO_STUDENT_SUCCESS = "@@uploadStudentData/ASSIGN_TEACHERS_TO_STUDENT_SUCCESS",
  ASSIGN_TEACHERS_TO_STUDENT_FAILURE = "@@uploadStudentData/ASSIGN_TEACHERS_TO_STUDENT_FAILURE",

  GET_CLASSES_LINKED_TO_STUDENT_START = "@@uploadStudentData/GET_CLASSES_LINKED_TO_STUDENT_START",
  GET_CLASSES_LINKED_TO_STUDENT_SUCCESS = "@@uploadStudentData/GET_CLASSES_LINKED_TO_STUDENT_SUCCESS",
  GET_CLASSES_LINKED_TO_STUDENT_FAILURE = "@@uploadStudentData/GET_CLASSES_LINKED_TO_STUDENT_FAILURE",

  GET_ALL_CLASSES_START = "@@uploadStudentData/GET_ALL_CLASSES_START",
  GET_ALL_CLASSES_SUCCESS = "@@uploadStudentData/GET_ALL_CLASSES_SUCCESS",
  GET_ALL_CLASSES_FAILURE = "@@uploadStudentData/GET_ALL_CLASSES_FAILURE",

  UPDATE_CLASSES_LINKED_TO_STUDENT_START = "@@uploadStudentData/UPDATE_CLASSES_LINKED_TO_STUDENT_START",
  UPDATE_CLASSES_LINKED_TO_STUDENT_SUCCESS = "@@uploadStudentData/UPDATE_CLASSES_LINKED_TO_STUDENT_SUCCESS",
  UPDATE_CLASSES_LINKED_TO_STUDENT_FAILURE = "@@uploadStudentData/UPDATE_CLASSES_LINKED_TO_STUDENT_FAILURE",

  CREATE_ASSESSMENT_START = "@@uploadStudentData/CREATE_ASSESSMENT_START",
  CREATE_ASSESSMENT_SUCCESS = "@@uploadStudentData/CREATE_ASSESSMENT_SUCCESS",
  CREATE_ASSESSMENT_FAILURE = "@@uploadStudentData/CREATE_ASSESSMENT_FAILURE",

  ADD_ASSESSMENT_TO_DATA_PERIOD_START = "@@uploadStudentData/ADD_ASSESSMENT_TO_DATA_PERIOD_START",
  ADD_ASSESSMENT_TO_DATA_PERIOD_SUCCESS = "@@uploadStudentData/ADD_ASSESSMENT_TO_DATA_PERIOD_SUCCESS",
  ADD_ASSESSMENT_TO_DATA_PERIOD_FAILURE = "@@uploadStudentData/ADD_ASSESSMENT_TO_DATA_PERIOD_FAILURE",

  SET_VALUE_OF_DSE_TO_DATA_PERIOD_START = "@@uploadStudentData/SET_VALUE_OF_DSE_TO_DATA_PERIOD_START",
  SET_VALUE_OF_DSE_TO_DATA_PERIOD_SUCCESS = "@@uploadStudentData/SET_VALUE_OF_DSE_TO_DATA_PERIOD_SUCCESS",
  SET_VALUE_OF_DSE_TO_DATA_PERIOD_FAILURE = "@@uploadStudentData/SET_VALUE_OF_DSE_TO_DATA_PERIOD_FAILURE",

  UPDATE_VALUE_OF_DSE_TO_DATA_PERIOD_START = "@@uploadStudentData/UPDATE_VALUE_OF_DSE_TO_DATA_PERIOD_START",
  UPDATE_VALUE_OF_DSE_TO_DATA_PERIOD_SUCCESS = "@@uploadStudentData/UPDATE_VALUE_OF_DSE_TO_DATA_PERIOD_SUCCESS",
  UPDATE_VALUE_OF_DSE_TO_DATA_PERIOD_FAILURE = "@@uploadStudentData/UPDATE_VALUE_OF_DSE_TO_DATA_PERIOD_FAILURE",

  SET_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_START = "@@uploadStudentData/SET_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_START",
  SET_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_SUCCESS = "@@uploadStudentData/SET_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_SUCCESS",
  SET_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_FAILURE = "@@uploadStudentData/SET_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_FAILURE",

  DELETE_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_START = "@@uploadStudentData/DELETE_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_START",
  DELETE_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_SUCCESS = "@@uploadStudentData/DELETE_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_SUCCESS",
  DELETE_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_FAILURE = "@@uploadStudentData/DELETE_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_FAILURE",

  DETECT_NON_EXISTING_STUDENT_IDS_START = "@@uploadStudentData/DETECT_NON_EXISTING_STUDENT_IDS_START",
  DETECT_NON_EXISTING_STUDENT_IDS_SUCCESS = "@@uploadStudentData/DETECT_NON_EXISTING_STUDENT_IDS_SUCCESS",
  DETECT_NON_EXISTING_STUDENT_IDS_FAILURE = "@@uploadStudentData/DETECT_NON_EXISTING_STUDENT_IDS_FAILURE",

  CLEAR_NON_EXISTING_STUDENTS = "@@uploadStudentData/REMOVE_NON_EXISTING_STUDENTS",

  CHANGE_TEACHERS_FILTER = "@@uploadStudentData/CHANGE_TEACHERS_FILTER",

  CHANGE_SELECTED_STUDENT = "@@uploadStudentData/evidence/CHANGE_SELECTED_STUDENT",

  CHANGE_SELECTED_STUDENT_ENTRY = "@@uploadStudentData/evidence/CHANGE_SELECTED_STUDENT_ENTRY",

  GET_DATA_PERIODS_START = "@@uploadStudentData/GET_DATA_PERIODS_START",
  GET_DATA_PERIODS_SUCCESS = "@@uploadStudentData/GET_DATA_PERIODS_SUCCESS",
  GET_DATA_PERIODS_FAILURE = "@@uploadStudentData/GET_DATA_PERIODS_FAILURE",

  GET_UPDATED_AREA_OF_NEED_START = "@@uploadStudentData/GET_UPDATED_AREA_OF_NEED_START",
  GET_UPDATED_AREA_OF_NEED_SUCCESS = "@@uploadStudentData/GET_UPDATED_AREA_OF_NEED_SUCCESS",
  GET_UPDATED_AREA_OF_NEED_FAILURE = "@@uploadStudentData/GET_UPDATED_AREA_OF_NEED_FAILURE",


  ADD_DATA_PERIOD_START = "@@uploadStudentData/ADD_DATA_PERIOD_START",
  ADD_DATA_PERIOD_SUCCESS = "@@uploadStudentData/ADD_DATA_PERIOD_SUCCESS",
  ADD_DATA_PERIOD_FAILURE = "@@uploadStudentData/ADD_DATA_PERIOD_FAILURE",

  UPDATE_DATA_PERIOD_START = "@@uploadStudentData/UPDATE_DATA_PERIOD_START",
  UPDATE_DATA_PERIOD_SUCCESS = "@@uploadStudentData/UPDATE_DATA_PERIOD_SUCCESS",
  UPDATE_DATA_PERIOD_FAILURE = "@@uploadStudentData/UPDATE_DATA_PERIOD_FAILURE",

  DELETE_DATA_PERIOD_START = "@@uploadStudentData/DELETE_DATA_PERIOD_START",
  DELETE_DATA_PERIOD_SUCCESS = "@@uploadStudentData/DELETE_DATA_PERIOD_SUCCESS",
  DELETE_DATA_PERIOD_FAILURE = "@@uploadStudentData/DELETE_DATA_PERIOD_FAILURE",

  CHANGE_DATA_PERIOD = "@@uploadStudentData/CHANGE_DATA_PERIOD_FILTER",

  GET_CUSTOM_COLOR_CRITERIA_START = "@@uploadStudentData/GET_CUSTOM_COLOR_CRITERIA_START",
  GET_CUSTOM_COLOR_CRITERIA_SUCCESS = "@@uploadStudentData/GET_CUSTOM_COLOR_CRITERIA_SUCCESS",
  GET_CUSTOM_COLOR_CRITERIA_FAILURE = "@@uploadStudentData/GET_CUSTOM_COLOR_CRITERIA_FAILURE",

  UPDATE_CUSTOM_COLOR_CRITERIA_START = "@@uploadStudentData/UPDATE_CUSTOM_COLOR_CRITERIA_START",
  UPDATE_CUSTOM_COLOR_CRITERIA_SUCCESS = "@@uploadStudentData/UPDATE_CUSTOM_COLOR_CRITERIA_SUCCESS",
  UPDATE_CUSTOM_COLOR_CRITERIA_FAILURE = "@@uploadStudentData/UPDATE_CUSTOM_COLOR_CRITERIA_FAILURE",

  RESTORE_DEFAULT_BENCHMARKS_START = "@@uploadStudentData/RESTORE_DEFAULT_BENCHMARKS_START",
  RESTORE_DEFAULT_BENCHMARKS_SUCCESS = "@@uploadStudentData/RESTORE_DEFAULT_BENCHMARKS_SUCCESS",
  RESTORE_DEFAULT_BENCHMARKS_FAILURE = "@@uploadStudentData/RESTORE_DEFAULT_BENCHMARKS_FAILURE",

  GET_STATIC_DATA_CATEGORY_DOMAINS_START = "@@uploadStudentData/evidence/GET_STATIC_DATA_CATEGORY_DOMAINS_START",
  GET_STATIC_DATA_CATEGORY_DOMAINS_SUCCESS = "@@uploadStudentData/evidence/GET_STATIC_DATA_CATEGORY_DOMAINS_SUCCESS",
  GET_STATIC_DATA_CATEGORY_DOMAINS_FAILURE = "@@uploadStudentData/evidence/GET_STATIC_DATA_CATEGORY_DOMAINS_FAILURE",

  GET_CUSTOM_BEHAVIORS_START = "@@uploadStudentData/evidence/GET_CUSTOM_BEHAVIORS_START",
  GET_CUSTOM_BEHAVIORS_SUCCESS = "@@uploadStudentData/evidence/GET_CUSTOM_BEHAVIORS_SUCCESS",
  GET_CUSTOM_BEHAVIORS_FAILURE = "@@uploadStudentData/evidence/GET_CUSTOM_BEHAVIORS_FAILURE",

  ADD_CUSTOM_BEHAVIOR_START = "@@uploadStudentData/evidence/ADD_CUSTOM_BEHAVIOR_START",
  ADD_CUSTOM_BEHAVIOR_SUCCESS = "@@uploadStudentData/evidence/ADD_CUSTOM_BEHAVIOR_SUCCESS",
  ADD_CUSTOM_BEHAVIOR_FAILURE = "@@uploadStudentData/evidence/ADD_CUSTOM_BEHAVIOR_FAILURE",

  ADD_CATEGORY_BEHAVIOR_START = "@@uploadStudentData/evidence/ADD_CATEGORY_BEHAVIOR_START",
  ADD_CATEGORY_BEHAVIOR_SUCCESS = "@@uploadStudentData/evidence/ADD_CATEGORY_BEHAVIOR_SUCCESS",
  ADD_CATEGORY_BEHAVIOR_FAILURE = "@@uploadStudentData/evidence/ADD_CATEGORY_BEHAVIOR_FAILURE",

  ADD_TARGET_BEHAVIOR_TO_DATA_PERIOD_START = "@@uploadStudentData/evidence/ADD_TARGET_BEHAVIOR_TO_DATA_PERIOD_START",
  ADD_TARGET_BEHAVIOR_TO_DATA_PERIOD_SUCCESS = "@@uploadStudentData/evidence/ADD_TARGET_BEHAVIOR_TO_DATA_PERIOD_SUCCESS",
  ADD_TARGET_BEHAVIOR_TO_DATA_PERIOD_FAILURE = "@@uploadStudentData/evidence/ADD_TARGET_BEHAVIOR_TO_DATA_PERIOD_FAILURE",

  CHANGE_STUDENT_DSE_GRADE_START = "@@uploadStudentData/CHANGE_STUDENT_DSE_GRADE_START",
  CHANGE_STUDENT_DSE_GRADE_SUCCESS = "@@uploadStudentData/CHANGE_STUDENT_DSE_GRADE_SUCCESS",
  CHANGE_STUDENT_DSE_GRADE_FAILURE = "@@uploadStudentData/CHANGE_STUDENT_DSE_GRADE_FAILURE",

  ADD_STUDENT_SKILLS_START = "@@uploadStudentData/ADD_STUDENT_SKILLS_START",
  ADD_STUDENT_SKILLS_SUCCESS = "@@uploadStudentData/ADD_STUDENT_SKILLS_SUCCESS",
  ADD_STUDENT_SKILLS_FAILURE = "@@uploadStudentData/ADD_STUDENT_SKILLS_FAILURE",

  DELETE_DATA_STUDENT_ENTRY_START = "@@uploadStudentData/DELETE_DATA_STUDENT_ENTRY_START",
  DELETE_DATA_STUDENT_ENTRY_SUCCESS = "@@uploadStudentData/DELETE_DATA_STUDENT_ENTRY_SUCCESS",
  DELETE_DATA_STUDENT_ENTRY_FAILURE = "@@uploadStudentData/DELETE_DATA_STUDENT_ENTRY_FAILURE",

  GET_DATA_SHARING_TABLE_DATA_START = "@@uploadStudentData/GET_DATA_SHARING_TABLE_DATA_START",
  GET_DATA_SHARING_TABLE_DATA_SUCCESS = "@@uploadStudentData/GET_DATA_SHARING_TABLE_DATA_SUCCESS",
  GET_DATA_SHARING_TABLE_DATA_FAILURE = "@@uploadStudentData/GET_DATA_SHARING_TABLE_DATA_FAILURE",

  UPDATE_DATA_SHARING_TABLE_DATA_START = "@@uploadStudentData/UPDATE_DATA_SHARING_TABLE_DATA_START",
  UPDATE_DATA_SHARING_TABLE_DATA_SUCCESS = "@@uploadStudentData/UPDATE_DATA_SHARING_TABLE_DATA_SUCCESS",
  UPDATE_DATA_SHARING_TABLE_DATA_FAILURE = "@@uploadStudentData/UPDATE_DATA_SHARING_TABLE_DATA_FAILURE",

  DELETE_DATA_SHARING_TABLE_DATA_START = "@@uploadStudentData/DELETE_DATA_SHARING_TABLE_DATA_START",
  DELETE_DATA_SHARING_TABLE_DATA_SUCCESS = "@@uploadStudentData/DELETE_DATA_SHARING_TABLE_DATA_SUCCESS",
  DELETE_DATA_SHARING_TABLE_DATA_FAILURE = "@@uploadStudentData/DELETE_DATA_SHARING_TABLE_DATA_FAILURE",

  OPEN_SHARE_DATA_MODAL = "@@uploadStudentData/OPEN_SHARE_DATA_MODAL",
  HIDE_SHARE_DATA_MODAL = "@@uploadStudentData/HIDE_SHARE_DATA_MODAL",

  OPEN_COACH_TEACHER_RELATION_MODAL = "@@uploadStudentData/OPEN_COACH_TEACHER_RELATION_MODAL",
  HIDE_COACH_TEACHER_RELATION_MODAL = "@@uploadStudentData/HIDE_COACH_TEACHER_RELATION_MODAL",

  SHARE_COACH_TEACHER_RELATION_DATA_START = "@@uploadStudentData/SHARE_COACH_TEACHER_RELATION_DATA_START",
  SHARE_COACH_TEACHER_RELATION_DATA_SUCCESS = "@@uploadStudentData/SHARE_COACH_TEACHER_RELATION_DATA_SUCCESS",
  SHARE_COACH_TEACHER_RELATION_DATA_FAILURE = "@@uploadStudentData/SHARE_COACH_TEACHER_RELATION_DATA_FAILURE",


  OPEN_DATA_SHARING_EDIT_MODAL = "@@uploadStudentData/OPEN_DATA_SHARING_EDIT_MODAL",
  HIDE_DATA_SHARING_EDIT_MODAL = "@@uploadStudentData/HIDE_DATA_SHARING_EDIT_MODAL",

  OPEN_GENERATE_PDF_MODAL = "@@uploadStudentData/OPEN_GENERATE_PDF_MODAL",
  HIDE_GENERATE_PDF_MODAL = "@@uploadStudentData/HIDE_GENERATE_PDF_MODAL",

  OPEN_INVITE_TEACHERS_MODAL = "@@uploadStudentData/OPEN_INVITE_TEACHERS_MODAL",
  HIDE_INVITE_TEACHERS_MODAL = "@@uploadStudentData/HIDE_INVITE_TEACHERS_MODAL",

  SET_NEW_INVITE_DATA = "@@uploadStudentData/SET_NEW_INVITE_DATA",

  GET_INTERVIEWS_START = "@@onboarding/GET_INTERVIEWS_START",
  GET_INTERVIEWS_SUCCESS = "@@onboarding/GET_INTERVIEWS_SUCCESS",
  GET_INTERVIEWS_FAILURE = "@@onboarding/GET_INTERVIEWS_FAILURE",

  CREATE_INTERVIEW_START = "@@onboarding/CREATE_INTERVIEW_START",
  CREATE_INTERVIEW_SUCCESS = "@@onboarding/CREATE_INTERVIEW_SUCCESS",
  CREATE_INTERVIEW_FAILURE = "@@onboarding/CREATE_INTERVIEW_FAILURE",

  UPDATE_INTERVIEW_START = "@@onboarding/UPDATE_INTERVIEW_START",
  UPDATE_INTERVIEW_SUCCESS = "@@onboarding/UPDATE_INTERVIEW_SUCCESS",
  UPDATE_INTERVIEW_FAILURE = "@@onboarding/UPDATE_INTERVIEW_FAILURE",

  GET_INTERVIEWS_STUDENTS_START = "@@onboarding/GET_INTERVIEWS_STUDENTS_START",
  GET_INTERVIEWS_STUDENTS_SUCCESS = "@@onboarding/GET_INTERVIEWS_STUDENTS_SUCCESS",
  GET_INTERVIEWS_STUDENTS_FAILURE = "@@onboarding/GET_INTERVIEWS_STUDENTS_FAILURE",

  CREATE_INTERVIEW_STUDENT_START = "@@onboarding/CREATE_INTERVIEW_STUDENT_START",
  CREATE_INTERVIEW_STUDENT_SUCCESS = "@@onboarding/CREATE_INTERVIEW_STUDENT_SUCCESS",
  CREATE_INTERVIEW_STUDENT_FAILURE = "@@onboarding/CREATE_INTERVIEW_STUDENT_FAILURE",

  UPDATE_INTERVIEW_STUDENT_START = "@@onboarding/UPDATE_INTERVIEW_STUDENT_START",
  UPDATE_INTERVIEW_STUDENT_SUCCESS = "@@onboarding/UPDATE_INTERVIEW_STUDENT_SUCCESS",
  UPDATE_INTERVIEW_STUDENT_FAILURE = "@@onboarding/UPDATE_INTERVIEW_STUDENT_FAILURE",

  UPLOAD_DOCUMENT_START = "@@onboarding/UPLOAD_DOCUMENT_START",
  UPLOAD_DOCUMENT_SUCCESS = "@@onboarding/UPLOAD_DOCUMENT_SUCCESS",
  UPLOAD_DOCUMENT_FAILURE = "@@onboarding/UPLOAD_DOCUMENT_FAILURE",

  ADD_STUDENT_BEHAVIORS_TO_DATA_PERIOD_START = "@@onboarding/ADD_STUDENT_BEHAVIORS_TO_DATA_PERIOD_START",
  ADD_STUDENT_BEHAVIORS_TO_DATA_PERIOD_SUCCESS = "@@onboarding/ADD_STUDENT_BEHAVIORS_TO_DATA_PERIOD_SUCCESS",
  ADD_STUDENT_BEHAVIORS_TO_DATA_PERIOD_FAILURE = "@@onboarding/ADD_STUDENT_BEHAVIORS_TO_DATA_PERIOD_FAILURE",

  GET_DEFAULT_PROGRESS_MONITORING_START = "@@onboarding/GET_DEFAULT_PROGRESS_MONITORING_START",
  GET_DEFAULT_PROGRESS_MONITORING_SUCCESS = "@@onboarding/GET_DEFAULT_PROGRESS_MONITORING_SUCCESS",
  GET_DEFAULT_PROGRESS_MONITORING_FAILURE = "@@onboarding/GET_DEFAULT_PROGRESS_MONITORING_FAILURE",

  ADD_DISTRICT_ASSESSMENT_START = "@@district/ADD_DISTRICT_ASSESSMENT_START",
  ADD_DISTRICT_ASSESSMENT_SUCCESS = "@@district/ADD_DISTRICT_ASSESSMENT_SUCCESS",
  ADD_DISTRICT_ASSESSMENT_FAILURE = "@@district/ADD_DISTRICT_ASSESSMENT_FAILURE",

  UPDATE_DISTRICT_ASSESSMENT_START = "@@district/UPDATE_DISTRICT_ASSESSMENT_START",
  UPDATE_DISTRICT_ASSESSMENT_SUCCESS = "@@district/UPDATE_DISTRICT_ASSESSMENT_SUCCESS",
  UPDATE_DISTRICT_ASSESSMENT_FAILURE = "@@district/UPDATE_DISTRICT_ASSESSMENT_FAILURE",

  GET_DISTRICT_RELATIONSHIPS_START = "@@onboarding/GET_DISTRICT_RELATIONSHIPS_START",
  GET_DISTRICT_RELATIONSHIPS_SUCCESS = "@@onboarding/GET_DISTRICT_RELATIONSHIPS_SUCCESS",
  GET_DISTRICT_RELATIONSHIPS_FAILURE = "@@onboarding/GET_DISTRICT_RELATIONSHIPS_FAILURE",

  GET_INTERVENTION_GROUP_LOGGING_START = "@@onboarding/GET_INTERVENTION_GROUP_LOGGING_START",
  GET_INTERVENTION_GROUP_LOGGING_SUCCESS = "@@onboarding/GET_INTERVENTION_GROUP_LOGGING_SUCCESS",
  GET_INTERVENTION_GROUP_LOGGING_FAILURE = "@@onboarding/GET_INTERVENTION_GROUP_LOGGING_FAILURE",

  ADD_TEACHERS_TO_COACHES_START = "@@onboarding/ADD_TEACHERS_TO_COACHES_START",
  ADD_TEACHERS_TO_COACHES_SUCCESS = "@@onboarding/ADD_TEACHERS_TO_COACHES_SUCCESS",
  ADD_TEACHERS_TO_COACHES_FAILURE = "@@onboarding/ADD_TEACHERS_TO_COACHES_FAILURE",

  REMOVE_TEACHERS_FROM_COACHES_START = "@@onboarding/REMOVE_TEACHERS_FROM_COACHES_START",
  REMOVE_TEACHERS_FROM_COACHES_SUCCESS = "@@onboarding/REMOVE_TEACHERS_FROM_COACHES_SUCCESS",
  REMOVE_TEACHERS_FROM_COACHES_FAILURE = "@@onboarding/REMOVE_TEACHERS_FROM_COACHES_FAILURE",

  ADD_EDUCATORS_TO_SUPERVISORS_START = "@@onboarding/ADD_EDUCATORS_TO_SUPERVISORS_START",
  ADD_EDUCATORS_TO_SUPERVISORS_SUCCESS = "@@onboarding/ADD_EDUCATORS_TO_SUPERVISORS_SUCCESS",
  ADD_EDUCATORS_TO_SUPERVISORS_FAILURE = "@@onboarding/ADD_EDUCATORS_TO_SUPERVISORS_FAILURE",

  REMOVE_EDUCATORS_FROM_SUPERVISORS_START = "@@onboarding/REMOVE_EDUCATORS_FROM_SUPERVISORS_START",
  REMOVE_EDUCATORS_FROM_SUPERVISORS_SUCCESS = "@@onboarding/REMOVE_EDUCATORS_FROM_SUPERVISORS_SUCCESS",
  REMOVE_EDUCATORS_FROM_SUPERVISORS_FAILURE = "@@onboarding/REMOVE_EDUCATORS_FROM_SUPERVISORS_FAILURE",

  ADD_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD_START = "@@onboarding/ADD_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD_START",
  ADD_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD_SUCCESS = "@@onboarding/ADD_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD_SUCCESS",
  ADD_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD_FAILURE = "@@onboarding/ADD_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD_FAILURE",

  ADD_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD_START = "@@onboarding/ADD_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD_START",
  ADD_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD_SUCCESS = "@@onboarding/ADD_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD_SUCCESS",
  ADD_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD_FAILURE = "@@onboarding/ADD_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD_FAILURE",

  GET_STUDENTS_DATA_START = "@@onboarding/GET_STUDENTS_DATA_START",
  GET_STUDENTS_DATA_SUCCESS = "@@onboarding/GET_STUDENTS_DATA_SUCCESS",
  GET_STUDENTS_DATA_FAILURE = "@@onboarding/GET_STUDENTS_DATA_FAILURE",

  OPEN_UPSERT_SCHOOL_MODAL = "@@district/OPEN_UPSERT_SCHOOL_MODAL",
  HIDE_UPSERT_SCHOOL_MODAL = "@@district/HIDE_UPSERT_SCHOOL_MODAL",

  OPEN_MANAGE_DATA_PERIODS_MODAL = "@@onboarding/OPEN_MANAGE_DATA_PERIODS_MODAL",
  HIDE_MANAGE_DATA_PERIODS_MODAL = "@@onboarding/HIDE_MANAGE_DATA_PERIODS_MODAL",

  CHANGE_CUSTOM_BEHAVIOR_NAME = "@@onboarding/CHANGE_CUSTOM_BEHAVIOR_NAME",

  IS_URL_BLOCKED_PAGE = "@@onboarding/IS_URL_BLOCKED_PAGE",

  INTERVENTION_GROUP_ACCESS = "@@onboarding/INTERVENTION_GROUP_ACCESS",

  SHOW_LOADING_INDICATOR = "@@onboarding/SHOW_LOADING_INDICATOR",
  HIDE_LOADING_INDICATOR = "@@onboarding/HIDE_LOADING_INDICATOR",
  GET_DATA_PERIOD_SCHOOLS_START = "@@onboarding/GET_DATA_PERIOD_SCHOOLS_START",
  GET_DATA_PERIOD_SCHOOLS_SUCCESS = "@@onboarding/GET_DATA_PERIOD_SCHOOLS_SUCCESS",
  GET_DATA_PERIOD_SCHOOLS_FAILURE = "@@onboarding/GET_DATA_PERIOD_SCHOOLS_FAILURE",

  GET_RACE_INFO_START = "@@onboarding/GET_RACE_INFO_START",
  GET_RACE_INFO_SUCCESS = "@@onboarding/GET_RACE_INFO_SUCCESS",
  GET_RACE_INFO_FAILURE = "@@onboarding/GET_RACE_INFO_FAILURE",

  GET_MASTER_RACE_INFO_START = "@@onboarding/GET_MASTER_RACE_INFO_START",
  GET_MASTER_RACE_INFO_SUCCESS = "@@onboarding/GET_MASTER_RACE_INFO_SUCCESS",
  GET_MASTER_RACE_INFO_FAILURE = "@@onboarding/GET_MASTER_RACE_INFO_FAILURE",

  RESET_STUDENT_ROSTER_UPLOAD = "@@onboarding/RESET_STUDENT_ROSTER_UPLOAD"
}

export type Student = {
  id?: number;
  unique_id?: string;
  focuses?: Array<SkillFocus>;
  teachers?: Array<BasicUserInfo>;
  //classes?: Array<number>;
  grade?: string;
  race?: Race;
  gender?: string;
  race_other_value?: string;
  first_name: string;
  last_name: string;
  special_education?: boolean;
  ell?: boolean;
  sld?: boolean;
  free_or_reduced_lunch?: boolean;
};

export enum Race {
  White,
  BlackOrAfricanAmerican,
  AmericanIndianAndAlaskaNative,
  Asian,
  NativeHawaiianAndOtherPacificIslander,
  TwoOrMoreRaces,
  HispanicOrLatino,
  Other,
}


export const RaceDisplayedNames: { [key in Race]: string } = {
  [Race.White]: "White",
  [Race.BlackOrAfricanAmerican]: "Black or African American",
  [Race.AmericanIndianAndAlaskaNative]: "American Indian and Alaska Native",
  [Race.Asian]: "Asian",
  [Race.NativeHawaiianAndOtherPacificIslander]:
    "Native Hawaiian and Other Pacific Islander",
  [Race.TwoOrMoreRaces]: "Two or More Races",
  [Race.HispanicOrLatino]: "Hispanic or Latino",
  [Race.Other]: "Other",
};

export const raceList = [
  { value: 0, label: "White" },
  { value: 1, label: "Black or African American" },
  { value: 2, label: "American Indian and Alaska Native" },
  { value: 3, label: "Asian" },
  { value: 4, label: "Native Hawaiian and Other Pacific Islander" },
  { value: 5, label: "Two or More Races" },
  { value: 6, label: "Hispanic or Latino" },
  { value: 7, label: "Other" },
];

export const demographicToggleList = [
  { value: true, label: "Y" },
  { value: false, label: "N" },
];

export const genderList = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "non_binary_undesignated", label: "Non-Binary/Undesignated" }
];

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  NONBINARYUNDESIGNATED = 'non_binary_undesignated'
} 

export const GenderDisplayedNames = {
  [Gender.MALE]: "Male",
  [Gender.FEMALE]: "Female",
  [Gender.NONBINARYUNDESIGNATED]: "Non-Binary/ Un- designated",
};

export type TeacherClass = {
  id?: number;
  name: string;
  students: Array<Student>;
};

export enum InviteStatuses {
  INVITE_SENT = "invite_sent",
  INVITE_ACCEPTED = "invite_accepted",
  INVITE_DECLINED = "invite_declined",
  INVITE_PENDING = "invite_pending",
}

export const InviteStatusesDisplayedNames = {
  [InviteStatuses.INVITE_SENT]: "Invite Sent",
  [InviteStatuses.INVITE_ACCEPTED]: "Invite Accepted",
  [InviteStatuses.INVITE_DECLINED]: "Invite Declined",
  [InviteStatuses.INVITE_PENDING]: "Invite Pending",
};

export type TeacherInfo = {
  user: UserInfo;
  invite: {
    id: number;
    status: InviteStatuses;
  };
  info: TeacherWorkloadInfo;
  resend_invite?: boolean;
};

export type CoachInfo = {
  user: UserInfo;
  invite: {
    id: number;
    status: InviteStatuses;
  };
  info: TeacherWorkloadInfo;
};

export type Reminder = {
  id: number;
  target_user: UserInfo;
  reminder_type: ReminderType;
  reminder_custom?: string;
  intervention_group: number;
  time: string;
  alert_creator: boolean;
  created: string;
  updated: string;
  creator: UserInfo;
  is_active: boolean;
};

export type ScheduleReminderRequest = {
  target_user: number;
  reminder_type: ReminderType;
  reminder_custom?: string;
  intervention_group: number;
  time: string;
  alert_creator: boolean;
};

export enum ReminderType {
  REMINDER_TYPE_CUSTOM = "custom",
  REMINDER_TYPE_PRIORITIZE = "prioritize",
}

export const ReminderTypeCategories = [
  { name: "Preparation", types: [ReminderType.REMINDER_TYPE_PRIORITIZE] },
  { name: "Custom", types: [ReminderType.REMINDER_TYPE_CUSTOM] },
];

export const ReminderTypeLabels = {
  [ReminderType.REMINDER_TYPE_CUSTOM]: "Custom",
  [ReminderType.REMINDER_TYPE_PRIORITIZE]: "Prioritize needs/set goals",
};

export const ReminderTypeColors = {
  [ReminderType.REMINDER_TYPE_CUSTOM]: "#757575",
  [ReminderType.REMINDER_TYPE_PRIORITIZE]: "#1a237e",
};

export type RenewedTeacherInfo = {
  first_name: string;
  last_name: string;
  email: string;
  schools: Array<number>;
  role?: UserAssignmentRole;
};

export type TeacherWorkloadInfo = {
  classes: number;
  students: number;
};

export type BasicUserInfo = {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  schools?: number[];
  classes?: StudentClass[] | null;
};

export type AddUserToDistrictRequest = {
  existing_user_id?: number;
  first_name: string;
  last_name: string;
  email: string;
  role?: UserAssignmentRole;
  isSetValue?: boolean;
  resend_invite?: boolean;
};

export type SendInviteRequest = {
  id?: number;
  existing_user_id?: number;
  originalUser?: UserInfo;
  first_name: string;
  last_name: string;
  email: string;
  grades?: Grade[];
  teachers?: number[];
  schools?: number[];
  role?: UserSpecification;
  all_data?: boolean;
  resend_invite?: boolean;
};

export type SuccessfulInvite = {
  user: UserInfo;
  invite: {
    id: number;
    status: InviteStatuses;
  };
  errors: any;
};

export type FailedInvite = {
  email: string;
  errors: {
    first_name?: Array<string>;
    last_name?: Array<string>;
    email?: Array<string>;
  };
};

export type InvitesResponse = {
  successful_invites: Array<SuccessfulInvite>;
  failed_invites: Array<FailedInvite>;
};

export type EmailCheckResponse = {
  emails_already_exist: [],
  new_emails: []
}

export type AddSchoolRequest = {
  name: string;
  district?: number;
};

export type School = {
  id: number;
  name: string;
  district: number;
  school_type: SchoolTypes;
  current_year?: SchoolYear;
  faculty: Array<UserInfo>;
};

export type SchoolYear = {
  id: number;
  is_current_year: boolean;
  display_name: string;
  start_date: string;
  end_date: string;
};

export enum SchoolTypes {
  ELEMENTARY,
  MIDDLE,
  HIGH,
}

export const SchoolTypesDisplayedNames = {
  [SchoolTypes.ELEMENTARY]: "Elementary",
  [SchoolTypes.MIDDLE]: "Middle",
  [SchoolTypes.HIGH]: "High",
};

export type EvidenceData = {
  raw_csv: string;
  csv_file_name: string;
  data_period_id: number;
  first_name_column: string;
  last_name_column: string;
  unique_id_column: string;
  teacher_column?: string;
  grade_column?: string;
  race_column?: string;
  assessment: {
    with_reading_algorithm?: boolean;
    use_grade_specific_color_criteria?: boolean;
    is_behavior?: boolean;
    name: string;
    description?: string;
    data_columns: Array<Measurement>;
  };
  target_evidence_column_group_id?: number;
};

export type Assessment = {
  id: number;
  name: string;
  unique_id_column: string;
  first_name_column: string;
  last_name_column: string;
  special_case?: string;
  source?: string;
};

export type AssessmentResponse = {
  matched: boolean;
  assessments: Array<Assessment>;
};

export enum KnownAssessment {
  ReadyCoach_Indicator = "assess_rc_indicator",
}

export type Measurement = {
  id?: number;
  created_from_file?: {
    id: number;
    original_file_url: string;
    original_filename: string;
  };
  color_criteria?: Array<ColorCriteria>;
  grade_specific_color_criteria?: GradeSpecificColorCriteria;
  custom_color_criteria?: Array<ColorCriteria>;
  display_name: string;
  skill_area?: SkillFocusArea;
  skill_areas?: string[];
  column_index?: number;
  column_name?: string;
  type?: MeasurementType;
  possible_values?: Array<PossibleValue> | null;
  range_min?: number;
  range_max?: number;
  is_gas?: boolean;
  brief?: AttachedResource;
  brief_url?: string;
  publisher_url?: string;
  assessment?: AssessmentData;
  known_assessment?: KnownAssessment;
  reading_skill?: string;
};

export type PossibleValue = {
  order: number;
  display_name: string;
};

export type ColorCriteria = {
  display_name: string;
  range_min?: number;
  range_max?: number;
  selected_value_order?: number;
  order?: number;
  color?: string;
  grade?: Grade;
  time_period?: TimePeriod;
  benchmark_status?: string;
  user_id?: number;
};

export enum BenchmarkStatus {
  WellBelow = "well_below",
  Below = "below",
  Average = "average",
  AtOrAbove = "at_or_above",
}

export const BenchmarkStatusDisplayedNames: {
  [key in BenchmarkStatus]: string;
} = {
  [BenchmarkStatus.WellBelow]: "Well Below",
  [BenchmarkStatus.Below]: "Below",
  [BenchmarkStatus.Average]: "Average",
  [BenchmarkStatus.AtOrAbove]: "Above",
};

export const BenchmarkStatusDefaultColors: {
  [key in BenchmarkStatus]: string;
} = {
  [BenchmarkStatus.WellBelow]: "#FFD1DA",
  [BenchmarkStatus.Below]: "#FFEFC1",
  [BenchmarkStatus.Average]: "#BFF9DD",
  [BenchmarkStatus.AtOrAbove]: "#DEEAF6",
};

export type GradeSpecificColorCriteria = {
  [grade_key: string]: {
    [time_period: string]: Array<ColorCriteria>;
  };
};

export enum MeasurementType {
  NUMBER = 0,
  PERCENTAGE = 1,
  CATEGORY = 2,
}

export type ReactSelectOption<T> = {
  label: string;
  value: T;
};

export const GradeLevels: ReactSelectOption<Grade>[] = [
  { value: "K", label: "Kindergarten" },
  { value: "G1", label: "1st Grade" },
  { value: "G2", label: "2nd Grade" },
  { value: "G3", label: "3rd Grade" },
  { value: "G4", label: "4th Grade" },
  { value: "G5", label: "5th Grade" },
  { value: "G6", label: "6th Grade" },
  { value: "G7", label: "7th Grade" },
  { value: "G8", label: "8th Grade" },
  { value: "G9", label: "9th Grade" },
  { value: "G10", label: "10th Grade" },
  { value: "G11", label: "11th Grade" },
  { value: "G12", label: "12th Grade" },
];

export const pdfTables: ReactSelectOption<any>[] = [
  {value: 'selected_interventions', label:'Selected Interventions'},
  {value: 'attendance', label: 'Attendance'},
  {value: 'my_notes_and_collaboration', label:'My Notes & Collaboration'},
  {value: 'action_taken', label:'Action Taken'},
  {value: 'student_goals', label:'Student Goals'},
  {value: 'logged_goal_progress', label:'Logged Goal Progress'},
  {value: 'logged_interventions', label:'Logged Interventions'},
  {value: 'focuses', label:'Focuses'},
]

export const ColorCriteriaBenchmarkNames = ["Above", "Below", "Well Below"];

export const ColorCriteriaGrade = [
  "Kindergarten",
  "Grade 1",
  "Grade 2",
  "Grade 3",
  "Grade 4",
  "Grade 5",
  "Grade 6",
  "Grade 7",
  "Grade 8",
  "Grade 9",
  "Grade 10",
  "Grade 11",
  "Grade 12",
];

export const MeasurementTypeDisplayedNames = {
  [MeasurementType.NUMBER]: "NUMBER",
  [MeasurementType.PERCENTAGE]: "PERCENTAGE",
  [MeasurementType.CATEGORY]: "CATEGORY",
};

export type UserSpecification =
  | "data_manager"
  | "principal"
  | "administrator"
  | "coach"
  | "teacher"
  | "njtss_imported";

export const UserSpecifications: ReactSelectOption<UserSpecification>[] = [
  { value: "data_manager", label: "Data Manager" },
  { value: "principal", label: "Principal" },
  { value: "administrator", label: "Other School Administrator" },
  { value: "coach", label: "Coach" },
  { value: "teacher", label: "Teacher" },
  { value: "njtss_imported", label: "Teacher (Imported from File)" },
];

export const skillAreaDisplayedNames = [
  {
    value: "phonics",
    label: "Phonics",
  },
  {
    value: "phonemic_awareness",
    label: "Phonemic Awareness",
  },
  {
    value: "fluency",
    label: "Fluency",
  },
  {
    value: "comprehension",
    label: "Comprehension",
  },
  {
    value: "vocabulary",
    label: "Vocabulary",
  },
];

export const measurementOptionsList: Array<string> = Object.keys(
  MeasurementType
).filter((value) => !isNaN(+value));

export type EvidencePeriodData = {
  evidence_column_groups: Array<EvidenceColumnGroup>;
  student_rows: Array<StudentRow>;
};

export type EvidenceColumnGroup = {
  id: number;
  created: string;
  assessment: AssessmentData;
  evidence_columns: Array<EvidenceColumn>;
  is_primary_screening: boolean;
  is_behaviour_primary_screening: boolean;
  meta_group_type:string;
  user_id: number;
};

export type AssessmentData = {
  id: number;
  name: string;
  created?: string;
  description: string | null;
  first_name_column: string;
  last_name_column: string;
  unique_id_column: string;
  is_district_assessment?: boolean;
  is_system_assessment?: boolean;
  special_case?: string;
  source?: string;
  is_behavior?: boolean;
  with_reading_algorithm?: boolean;
  use_grade_specific_color_criteria?: boolean;
  owner?: any | null
};

export enum EvidenceType {
  Data = 0,
  Interview = 1,
  TargetBehavior = 2,
  NeedAreas = 3,
  DiagnosticInfo = 4,
  ExistingBehavior = 5,
  BehaviorScreener = 6,
  DiagnosticFollowUp = 7,
}

export type EvidenceColumn = {
  id: number;
  created: string;
  evidence_type: EvidenceType;
  student_entries: Array<StudentEntry>;
  measurement: Measurement;
};

export enum DiagnosticTagType {
  Area = "area",
  SkillFocus = "skill_focus",
  Specific = "specific",
}

export type NeedsAreaTag = {
  id: number;
  is_active: boolean;
  created?: string;
  updated?: string;
  tag: string;
  display_name: string;
};

export type DiagnosticTag = {
  id: number;
  is_active: boolean;
  created?: string;
  updated?: string;
  tag_type: DiagnosticTagType;
  display_name: string;
  area?: string;
  skill_focus?: string;
  specific?: string;
  diagnosed_need_area: string;
  column: number;
  entry: number;
  group: number;
  student: number;
  data_period: number;
};

export type StudentEntry = {
  student_id: number;
  entry_id: number;
  value?: string;
  gating_file_value?: string | null;
  behavior_tags?: Array<CategoryBehavior>;
  target_behavior?: TargetBehaviorResponse;
  needs_area_tags?: Array<NeedsAreaTag>;
  diagnostic_tags?: Array<DiagnosticTag>;
  grade?: Grade;
  missing_area?: string;
  extra_info?: any;
};

export type StudentRow = {
  id: number;
  student: Student;
  data_period: number;
  grade?: Grade;
};

export type EvidenceAssessment = AssessmentData & {
  enabled: boolean;
  categories: Array<CategoryBehavior>;
  measurements: Array<Measurement>;
};

export type EvidenceAssessmentRequest = {
  enabled?: boolean;
  name: string;
  description?: string | null;
  categories: Array<number>;
  measurements: Array<Measurement>;
};

export type CategoryBehaviorRequest = {
  is_active: boolean;
  order: number;
  name: string;
  category_type: number;
  full_display_name: string;
  definition: string;
  example: string;
  domain: number;
};

export type ManuallyAssessment = {
  assessment_id: number;
  measurement_ids: Array<number>;
};

export type DataStudentEntry = {
  student_id?: number;
  evidence_column_id?: number;
  value?: number | boolean | string;
  grade?: string;
  selected_value_order?: number;
  target_behavior?: TargetBehaviorResponse;
  extra_info?: any;
};

export type DataStudentEntryResponse = {
  id: number;
  created: string;
  updated: string;
  uploaded_data_file: any;
  student: Student;
  data_period_id: number;
  evidence_column: EvidenceColumn;
  value?: string;
  grade?: Grade;
  target_behavior?: TargetBehaviorResponse;
};

export type DiagnosticFollowUpResponse = {
  entry_id: number;
  student_id: number;
  evidence_column_id: number;
  value: string;
};

export type DseAffectsResponse = {
  data_student_entry: DataStudentEntryResponse;
  changed_columns?: Array<EvidenceColumn>;
  changed_groups?: Array<EvidenceColumnGroup>;
  extra_info?: any;
};

export type DataPeriod = {
  id: number;
  user_id: number;
  name: string;
  year: string;
  is_active: boolean;
  assigment_id: number;
  school_year_id: number;
  school_year: Partial<SchoolYear>;
  is_selected: boolean;
  time_period: TimePeriod;
  user_name: string;
  user_email: string;
  evidence_column_groups?: Array<EvidenceColumnGroup>;
  all_data_period_ids?: Array<number>;
};

export type CategoryBehavior = {
  id?: number;
  created: string;
  is_active: boolean;
  order: number;
  category_type: TargetBehaviorCategoryType;
  name: string;
  full_display_name: string;
  domain: CategoryDomain;
};

export type CategoryDomain = {
  id: number;
  created: string;
  is_active: true;
  order: number;
  name: string;
  category_type: TargetBehaviorCategoryType;
};

export enum TargetBehaviorCategoryType {
  NEGATIVE = 0,
  POSITIVE = 1,
  CUSTOM = 2,
}

export type TargetBehavior = {
  is_active: boolean;
  target_behavior_type: TargetBehaviorCategoryType;
  before_activity_context: string;
  before_student_context: string;
  before_teacher_context: string;
  before_classmate_context: string;
  after_activity_context: string;
  after_student_context: string;
  after_teacher_context: string;
  after_classmate_context: string;
  statement: string;
  intervention_group?: number;
  student: number;
  behavior: number;
  replacement: number;
};

export type TargetBehaviorResponse = {
  id: number;
  is_active: boolean;
  target_behavior_type: TargetBehaviorCategoryType;
  before_activity_context: string;
  before_student_context: string;
  before_teacher_context: string;
  before_classmate_context: string;
  after_activity_context: string;
  after_student_context: string;
  after_teacher_context: string;
  after_classmate_context: string;
  statement: string;
  intervention_group: number;
  student: Student;
  behavior: CategoryBehavior;
  replacement?: CategoryBehavior;
};

export type DataSharingTableData = {
  id: number;
  user: UserInfo;
  grades?: Array<Grade>;
  teachers: Array<UserInfo>;
  schools?: Array<School>;
  parent: number;
  relationship_status: number;
  relationship_type: number;
  school_year: number;
  updated: string;
  all_data: boolean;
};

export type InterviewRequest = {
  attachments: Array<number>;
  is_active: boolean;
  completed: boolean;
  teacher: number;
  data_period: number;
};

export type Interview = {
  id: number;
  created: string;
  updated: string;
  is_active: boolean;
  completed: boolean;
  created_by_user: UserInfo;
  teacher: UserInfo;
  students: Array<InterviewStudent>;
  data_period: number;
  attachments: Array<AttachedResource>;
};

export type InterviewStudentRequest = {
  think_relationship: boolean;
  think_behavior: string;
  think_context: string;
  interview: number;
  student: number;
};

export type InterviewStudent = {
  id: number;
  created: string;
  updated: string;
  is_active: boolean;
  think_relationship: boolean;
  think_behavior: string;
  think_context: string;
  interview: number;
  student: Student;
};

export type CoachTeacherRelationshipRequest = {
  coach: number;
  teacher: number;
};

export type SupervisorEducatorRelationshipRequest = {
  supervisor: number;
  user: number;
};

export type StudentsDataResponse = EvidencePeriodData & {
  interviews: Array<Interview>;
};

export type InviteParentRequest = {
  first_name: string;
  last_name: string;
  email: string;
  children?: number[];
};

export type DistrictAccessDataSetting<
  TDistrict extends District | number = number,
  TUserInfo extends UserInfo | number = number,
  TSchool extends School | number = number
> = {
  id: number;
  district: TDistrict;
  user: TUserInfo;
  allowed_grades: Grade[] | undefined;
  allowed_no_grade: boolean;
  allowed_assigned_teachers: number[] | undefined; // todo??
  // allowed_assigned_teachers: TUserInfo[] | undefined;
  allowed_no_teacher: boolean;
  allowed_schools: TSchool[] | undefined;
  allowed_edit_benchmarks:boolean;
  readonly?: boolean;
  status?: boolean;
  share_all_data?: boolean;
  user_ids?:any;
  block_access_to_all_data?: boolean;
};

export enum BehaviorScreenState {
  NEVER = 0,
  OCCASIONALLY = 1,
  SOMETIMES = 2,
  FREQUENTLY = 3,
}

export const ElementaryGrade = [
  "K",
  "G1",
  "G2",
  "G3",
  "G4",
  "G5",
];

export const MiddleHighGrade = [
  "G6",
  "G7",
  "G8",
  "G9",
  "G10",
  "G11",
  "G12",
];

export type RaceInfo = {
  races: string[];
  race_other_values: string[];
};